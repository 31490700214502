import { cloneDeep, isNil } from 'lodash'

import { VALID_TABLES } from '../../constants'
import Client, { setSelectValidData, TYPE } from '../../models/dto/Client'
import ClientService from '../../services/api/client.service'

const client = {
  namespaced: true,
  state: {
    clientCache: {},                 // Cached clients from the current session.
    clientListCache: [],             // List of all clients.
    clientWithLoanCodeListCache: [], // List of all clients including loan codes.
  },
  getters: {
    getCachedClient: state => client_id => cloneDeep(state.clientCache[client_id]),
    getCachedClientList: state => () => state.clientListCache,
    getCachedClientWithLoanCodeList: state => () => state.clientWithLoanCodeListCache,
    getFromClientList: state => client_id => _.filter(state.clientListCache, client => client.client_id === client_id),
    
    hasCachedClientList: state => () => state.clientListCache.length > 0,
    hasCachedClientWithLoanCodeList: state => () => state.clientWithLoanCodeListCache.length > 0,
    
    isClientCached: state => client_id => !isNil(state.clientCache[client_id]),
  },
  mutations: {
    cacheClient (state, { client, client_id }) {
      state.clientCache[client_id] = cloneDeep(client)
    },
    cacheClientList (state, { list }) {
      state.clientListCache = cloneDeep(list)
    },
    cacheClientWithLoanCodeList (state, { list }) {
      state.clientWithLoanCodeListCache = cloneDeep(list)
    },
    clearCache (state) {
      state.clientCache = {}
    },
    removeFromCache (state, { client_id }) {
      delete state.clientCache[client_id]
    },
  },
  actions: {
    createData ({ commit }, { type, payload }) {
      const endpointConfig = {
        type,
        client_id: payload.client_id,
      }

      return ClientService.create(endpointConfig, payload)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    createFullClient ({ commit, dispatch, getters }, { payload }) {
      return ClientService.createFull(payload)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    deleteData ({ commit }, { type, client_id, id }) {
      const endpointConfig = { type, client_id, id, }

      return ClientService.delete(endpointConfig)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    fetchDetail ({ commit }, { type, client_id, id }) {
      const endpointConfig = { type, client_id, id, }

      return ClientService.detail(endpointConfig)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    fetchList ({ commit }, { type, client_id }) {
      const endpointConfig = { type, client_id, }

      return ClientService.list(endpointConfig)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    updateData ({ commit }, { type, payload }) {
      const endpointConfig = {
        type,
        client_id: payload.client_id,
        id: payload.id ? payload.id : null,
      }

      return ClientService.update(endpointConfig, payload)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    async fetchClient ({ dispatch, commit, getters }, { client_id, force = false }) {
      // If the Client is cached or we're not forcing the fetch, return the cached Client.
      if (getters.isClientCached(client_id) && !force) {
        return getters.getCachedClient(client_id)
      }
      // Otherwise, fetch it, cache the new Client, and return it to the caller.

      // Fetch valid table dependencies and pass them to Client.
      try {
        const provinces = await dispatch('valid/fetchList', { type: VALID_TABLES.PROVINCE, }, { root: true, })
        setSelectValidData({ provinces, })
      }
      catch(error) {
        throw 'Error fetching valid provinces: ' + error
      }

      let data = {};
      return dispatch('fetchDetail', { type: TYPE.CLIENT, client_id, })
        .then(primaryData => {
          // Store primary Client data.
          data[TYPE.CLIENT] = primaryData

          return primaryData
        })
        .then(async (primaryData) => {
          // Store secondary Client data.
          try {
            const secondaryData = await Promise.all([
              dispatch('fetchList', { type: TYPE.CLIENT_ADDRESS, client_id, }),
              dispatch('fetchList', { type: TYPE.CLIENT_CONTACT, client_id, }),
              dispatch('fetchList', { type: TYPE.CLIENT_PROGRAM_CONTROL, client_id, }),
              dispatch('fetchList', { type: TYPE.CLIENT_AGENT_ASSN, client_id, }),
              dispatch('fetchList', { type: TYPE.CLIENT_MORTGAGE_DEAL_ASSN, client_id, }),
            ])

            data[TYPE.CLIENT_ADDRESS] = secondaryData[0];
            data[TYPE.CLIENT_CONTACT] = secondaryData[1];
            data[TYPE.CLIENT_PROGRAM_CONTROL] = secondaryData[2];
            data[TYPE.CLIENT_AGENT_ASSN] = secondaryData[3];
            data[TYPE.CLIENT_MORTGAGE_DEAL_ASSN] = secondaryData[4];
          }
          catch(error) {
            throw `Error fetching secondary data: ${error}`
          }
          return data
        })
        .then(data => {
          // Create, cache, and return the Client.
          let client = new Client(data)
          commit('cacheClient', { client, client_id, })

          return client
        })
        .catch(error => {
          if (error.status_code !== 404) {
            throw 'Error fetching Client object: ' + error
          }
          return null
        })
    },

    fetchClients ({ dispatch, commit, getters }, { force = false }) {
      // If the client list is cached or we're not forcing the fetch, return the cached client list.
      if (getters.hasCachedClientList() && !force) {
        return getters.getCachedClientList()
      }

      // Otherwise, fetch it, cache the new client list, and return it to the caller.
      return dispatch('fetchList', { type: TYPE.CLIENT, })
        .then(list => {
          commit('cacheClientList', { list, })
          return list
        })
        .catch(error => {
          throw 'Error fetching client list: ' + error
        })
    },

    fetchClientsWithLoanCode ({ commit, getters }, { force = false }) {
      // If the client list is cached or we're not forcing the fetch, return the cached client list.
      if (getters.hasCachedClientWithLoanCodeList() && !force) {
        return getters.getCachedClientWithLoanCodeList()
      }

      // Otherwise, fetch it, cache the new client list, and return it to the caller.
      return ClientService.withLoanCode()
        .then(list => {
          commit('cacheClientWithLoanCodeList', { list, })
          return list
        })
        .catch(error => {
          throw 'Error fetching client with loan code list: ' + error
        })
    },
  },
}

export default client