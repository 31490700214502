import { isNil, inRange, indexOf, values } from 'lodash'

// Valid field types.
export const FIELD_TYPES = Object.freeze({
  TEXT: 'TEXT',
  DATE: 'DATE',
  SELECT: 'SELECT',
});

const REQUIRED_SYMBOL = '**'

/**
 * Abstract FieldConfig class intended for Vuetify field components.
 */
export default class FieldConfig {

  /**
   * Create a FieldConfig object
   * @param {string} fieldType FIELD_TYPE of the field.
   * @param {string} property Data-type property represented by the field.
   * @param {string} label Label of the field.
   * @param {string} smColumns Number of columns (1-12) that this field spans at the "sm" breakpoint or larger.
   * @param {string} mdColumns Number of columns (1-12) that this field spans at the "md" breakpoint or larger.
   * @param {string} iconPrepend Icon to prepend the field with.
   * @param {boolean} required Indicate that the field requires a value.
   */
  constructor ({ fieldType = null, property = null, label = null, smColumns = null, mdColumns = null, iconPrepend = null, required = null }) {
    if (this.constructor === FieldConfig) {
      throw new TypeError("Can not construct abstract class FieldConfig.");
    }

    this.fieldType = fieldType
    this.property = property
    this.required = required
    this.label = required ? `${label}${REQUIRED_SYMBOL}` : label
    this.smColumns = smColumns
    this.mdColumns = mdColumns
    this.iconPrepend = iconPrepend

    this.validate()
  }

  validate () {
    if (indexOf(values(FIELD_TYPES), this.fieldType) === -1) {
      throw new ReferenceError(`Field type must be one of FieldConfig.FIELD_TYPES`)
    }

    if (isNil(this.property)) {
      throw new ReferenceError(`Property must have a value.`)
    }

    if (isNil(this.label)) {
      throw new ReferenceError(`Label must have a value.`)
    }

    if (!inRange(this.smColumns, 1, 13)) {
      throw new RangeError("smColumns must be in the range 1 to 12, inclusive.")
    }

    if (!inRange(this.mdColumns, 1, 13)) {
      throw new RangeError("mdColumns must be in the range 1 to 12, inclusive.")
    }
  }

  getFieldType () {
    return this.fieldType
  }

  getProperty () {
    return this.property
  }

  getLabel () {
    return this.label
  }

  getSmColumns () {
    return this.smColumns
  }

  getMdColumns () {
    return this.mdColumns
  }

  getIconPrepend () {
    return this.iconPrepend
  }

  isRequired () {
    return this.required
  }
}