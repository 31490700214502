import Vue from 'vue'
import VueRouter from 'vue-router'
import { startCase } from 'lodash'

import { ROUTES } from '../constants'
import AuthService from '../services/api/auth.service'

Vue.use(VueRouter)

const routes = [
  {
    path: ROUTES.ROOT,
    redirect: ROUTES.SIGN_IN,
    meta: {
      name: 'iCare CRM',
    },
  },
  {
    path: ROUTES.NOT_FOUND,
    component: () => import('../views/404.vue'),
    meta: {
      requireSignIn: false,
      name: '404 Not Found',
      disableMenus: true,
    },
  },
  {
    path: ROUTES.SIGN_IN,
    name: 'SignIn',
    component: () => import('../views/SignIn.vue'),
    meta: {
      requireSignIn: false,
      name: 'Sign-In',
    },
  },
  {
    path: `${ROUTES.ACTIVATE}/:uid/:token`,
    name: 'Activate',
    component: () => import('../views/Activate.vue'),
    meta: {
      requiresSignIn: false,
      name: 'Account Activation',
    },
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:uid/:token`,
    name: 'Reset Password',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      requiresSignIn: false,
      name: 'Reset Password',
    },
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    name: 'Forgot Password',
    component: () => import('../views/ForgotPassword.vue'),
    meta: {
      requiresSignIn: false,
      name: 'Forgot Password',
    },
  },
  {
    path: ROUTES.DASHBOARD,
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requireSignIn: true,
      name: 'Dashboard',
    },
    children: [
      {
        path: 'account',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        meta: {
          requireSignIn: true,
          name: 'Account',
        },
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('../views/Reports.vue'),
        meta: {
          requireSignIn: true,
          name: 'Reports',
        },
      },
      {
        path: 'reports/:id',
        name: 'Report',
        component: () => import('../views/Report.vue'),
        meta: {
          requireSignIn: true,
          name: 'Report',
        },
      },
      {
        path: 'mortgage-deals',
        name: 'MortgageDeals',
        component: () => import('../views/MortgageDeals.vue'),
        meta: {
          requireSignIn: true,
          name: 'Mortgage Deals',
        },
      },
      {
        path: 'mortgage-deals/:id',
        name: 'MortgageDeal',
        component: () => import('../views/MortgageDeal.vue'),
        meta: {
          requireSignIn: true,
          name: 'Mortgage Deal',
        },
      },
      {
        path: 'agents',
        name: 'Agents',
        component: () => import('../views/Agents.vue'),
        meta: {
          requireSignIn: true,
          name: 'Agents',
        },
      },
      {
        path: 'agents/:id',
        name: 'Agent',
        component: () => import('../views/Agent.vue'),
        meta: {
          requireSignIn: true,
          name: 'Agent',
        },
      },
      {
        path: 'clients',
        name: 'Clients',
        component: () => import('../views/Clients.vue'),
        meta: {
          requireSignIn: true,
          name: 'Clients',
        },
      },
      {
        path: 'clients/:id',
        name: 'Client',
        component: () => import('../views/Client.vue'),
        meta: {
          requireSignIn: true,
          name: 'Client',
        },
      },
      {
        path: 'mailing-schedule',
        name: 'MailingSchedule',
        component: () => import('../views/MailingSchedule.vue'),
        meta: {
          requireSignIn: true,
          name: 'Mailing Schedule',
        },
      },
      {
        path: 'load-data',
        name: 'LoadData',
        component: () => import('../views/LoadData.vue'),
        meta: {
          requireSignIn: true,
          name: 'Load Data',
        },
      },
      {
        path: 'load-data/new/:id',
        name: 'LoadNewData',
        component: () => import('../views/LoadNewData.vue'),
        meta: {
          requireSignIn: true,
          name: 'Load New Data',
        },
      },
      {
        path: 'load-data/update/:id',
        name: 'LoadUpdateData',
        component: () => import('../views/LoadUpdateData.vue'),
        meta: {
          requireSignIn: true,
          name: 'Load Update Data',
        },
      },
      {
        path: 'valid-tables',
        name: 'ValidTables',
        component: () => import('../views/ValidTables.vue'),
        meta: {
          requireSignIn: true,
          name: 'Valid Tables',
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Redirect users who have not signed in to the sign-in page.
router.beforeEach((to, from, next) => {
  setPageTitle(to)

  AuthService.isAuthenticated()
    .then(authenticated => {
      if (authenticated) {
        next()
      } else {
        routeGuard(to, from, next)
      }
    })
})

const setPageTitle = (to) => {
  if (to.params.id) {
    document.title = to.meta.name ? `${to.meta.name} - ${startCase(to.params.id)}` : 'No Page Title';
  } else {
    document.title = to.meta.name ? to.meta.name : 'No Page Title';
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta);

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta.meta.name) {
    return
  }

  const tag = document.createElement('meta')
  tag.setAttribute('name', nearestWithMeta.meta.name)
  tag.setAttribute('data-vue-router-controlled', '')

  document.head.appendChild(tag)
}

const routeGuard = (to, from, next) => {
  if (to.matched.some(route => route.meta.requireSignIn)) {
    next(ROUTES.SIGN_IN)
  }
  else {
    next()
  }
}

/**
 * Wrapper for VueRouter.push() that handles the NavigationDuplicated error.
 * @param {string} route Route string.
 */
export const push = route => {
  router.push(route)
    .catch(error => {
      const navigationGuardRedirection = /Redirected when going from .* to .* via a navigation guard/.test(error.message)
      const navigationDupicated = /Avoided redundant navigation to current location: .*/.test(error.message)

      // Ignore these vue-router errors.
      if (!navigationGuardRedirection && !navigationDupicated) {
        console.error('unhandled push() error:', error)
      }
    })
}

export default router
