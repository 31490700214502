import Vue from 'vue'
import Vuex from 'vuex'

import agent from './modules/agent.store'
import calendarEvent from './modules/calendar.event.store'
import client from './modules/client.store'
import help from './modules/help.store'
import message from './modules/message.store'
import mortgageDeal from './modules/mortgage.deal.store'
import report from './modules/report.store'
import sitemap from './modules/sitemap.store'
import user from './modules/user.store'
import valid from './modules/valid.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    agent,
    calendarEvent,
    client,
    help,
    message,
    mortgageDeal,
    report,
    sitemap,
    user,
    valid,
  }
})