import moment from 'moment-timezone'
import { countBy, map } from 'lodash'

// ================
// Public Functions
// ================

const dateFormat = 'YYYY-MM-DD'
const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
const timezone = 'Canada/Eastern'

/**
 * Add days to a date and return the new date.
 * @param {string} date Date to have days added to it.
 * @param {number} n Number of days to add.
 * @returns Date the occurs n days after the original date.
 */
export const addDays = (date, n) => {
  return moment.tz(date, dateFormat, timezone).add(n, 'd').format(dateFormat)
}

/**
  * Get an array containing duplicate elements from the provided array.
  * @param {array} array Array to get duplicates elements from. 
  * @returns An array containing duplicate elements from the provided array.
  */
export const duplicates = array => {
  let duplicates = []
  map(countBy(array), (val, key) => {
    if (val > 1) {
      duplicates.push(key)
    }
  })
  return duplicates
}

/**
 * Get a URL for a given type, type ID, and subtype.
 * @param {string} type Type of data.
 * @param {string} typeID ID for type.
 * @param {string} subtype Subtype of data.
 * @returns A URL formatted for that object.
 */
export const getURL = (type, typeID, subtype) => {
  let URL = type + '/'  + typeID + '/'
  if (subtype !== type) {
    URL += _.kebabCase(subtype.replace(type, '')) + '/'
  }
  return URL
}

export const appendUUID = (string) => {
  if (string.length === 30) {
    return string
  }

  let uuid = string.split('')

  while (uuid.length < 30) {
    uuid.push(Math.floor((Math.random() * 10)))
  }

  return uuid.join('')
}

/**
 * Get the current date-time in string format. 
 * @returns Date-time string formatted for a MySQL date column.
 */
export const now = () => {
  return moment.tz(timezone).format(dateTimeFormat)
}

/**
 * Get the current date.
 * @returns Today's date.
 */
export const today = () => {
  return moment.tz(timezone).format(dateFormat)
}

/**
 * Get the name of a day of the week for a given date.
 * @param {date} date
 * @returns Name of the day for this date.
 */
export const getDay = (date) => {
  return moment(date).tz(timezone).format('dddd')
}

/**
 * Get the number of days between today and a date.
 * @param {*} date 
 * @returns The difference in days between a date and today, as a number.
 */
export const daysAway = (date) => {
  const start = today()
  const end = moment(date).tz(timezone)
  return end.diff(start, 'days')
}

/**
 * Converts a server error message into a user-friendly error message.
 * @param {*} error error.response.data
 * @returns Custom error message to display to user.
 */
export const transformError = error => {
  switch (error.status_code) {
    case 400:
      // Handle Djoser errors.
      if (error.uid) {
        // Djoser activate user error (1).
        if (Array.isArray(error.uid) && error.uid[0] === "Invalid user id or user doesn't exist.") {
          return "Invalid user id or user does not exist."
        }
      }
      else if (error.email) {
        // Djoser create user error (1).
        if (Array.isArray(error.email)) {
          switch (error.email[0]) {
            case 'user account with this email already exists.':
              return 'User account with this email already exists.'
            case 'Enter a valid email address.':
              return error.email[0]
          }
        }
      }
      else if (error.password) {
        // Djoser create user error (2).
        if (Array.isArray(error.password) && error.password[0] === 'This password is too common.') {
          return 'Password is too common, please use another.'
        }
      }
      else if (error.non_field_errors) {
        // Djoser create user error (3).
        if (Array.isArray(error.non_field_errors) && error.non_field_errors[0] === 'The two password fields didn\'t match.') {
          return 'The two password fields did not match.'
        }
      }
      else if (error.token) {
        // Djoser reset password error (1).
        if (Array.isArray(error.token) && error.token[0] === "Invalid token for given user.") {
          return 'Reset password token has expired.'
        }
      }

      // Catches validation errors for CREATE and UPDATE actions.
      if (/^.*with this.*already exist.*$/.test(error.message)) {
        return error.message
      }
      else if (error.error_code === 'blank' && error.message === 'This field may not be blank.') {
        return error.message.replace('This', `The ${error.error_object}`)
      }
      else if (error.message === `"mortgage_maturity_date" column's date must occur after "mortgage_closing_date" column's date`) {
        return`The "Maturity Date" must occur after the "Closing Date".`
      }
      break

    case 401:
      if (error.message === 'No active account found with the given credentials') {
        return 'No active account found with the given credentials.'
      }
      break
    
    case 403:
      break

    case 404:
      if (/^No .* matches the given query.$/.test(error.message)) {
        return 'Could not find a matching entry in the database.'
      }
      break

    case 424:
      return transformMySQLError(error.mysql)
  }
  
  if (error.message) {
    if (error.message.length === 0) {
      return 'Empty error message.'
    }
    return error.message
  }

  return 'No error message.'
}

/**
 * Converts a MySQL error message into a user-friendly error message.
 * @param {*} mysqlError MySQL object from the error.response.data.
 * @returns Custom error message to display to user.
 */
 export const transformMySQLError = mysqlError => {
  let message = 'No MySQL error message provided.'
  switch (mysqlError.error_code) {
    case 1217:
      if (mysqlError.message === 'Cannot delete or update a parent row: a foreign key constraint fails') {
        message = 'Cannot delete. This entry is used by another object.'
      }
      break
  }
  return message
}

export const monthNumberToName = number => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',]
  return months[number - 1]
}

/**
 * Determines is a file has the UTF-8 character set.
 * @param {*} file File to test.
 * @returns true when the file is UTF-8 compatible, false otherwise.
 */
export const isUtf8 = file => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsText(file, 'UTF-8');
    reader.onload = (e) => {
      resolve(!reader.result.includes('�'))
    }
  })
}