import { VALID_TABLES, ACTIONS, MESSAGE_TYPE, } from '../constants'
import { TYPE as AGENT_TYPE } from './dto/Agent'
import { TYPE as CLIENT_TYPE } from './dto/Client'
import { TYPE as MORTGAGE_DEAL_TYPE } from './dto/MortgageDeal'
import { transformError } from '../services/utility.service'

const DEFAULT_TIMEOUT = 3000
const TRANSIITON = 'fade'


export class ErrorMessage {

    constructor(message) {
      this.color = 'error'
      this.message = {
        buttonColor: 'error',
        dismissable: true,
        text: message,
      }
      this.timeout = -1
      this.transition = TRANSIITON
    }

}

export class SuccessMessage {

  constructor(message) {
    this.color = 'success'
    this.message = {
      buttonColor: 'success',
      dismissable: true,
      text: message,
    }
    this.timeout = DEFAULT_TIMEOUT
    this.transition = TRANSIITON
  }

}


export default class Message {
  /**
   * Create a message.
   * @param {string} action Action that triggered this message.
   * @param {string} data Data to represent in the message.
   * @param {error} error Error object to represent in the message.
   * @param {string} message Text of the message.
   * @param {string} type Type of message to send.
   * @param {string} dataType Data-type of the data.
   */
  constructor({ action, data, error, message, type, dataType }) {
    this.action = action
    this.data = data
    this.message = message
    this.error = error
    this.type = type
    this.dataType = dataType
  }

  /**
   * Get type-specific information from the data to be displayed in the message.
   * @returns String representation of the data in the message.
   */
  formatData () {
    if (!this.data) {
      return
    }

    switch (this.dataType) {
      // === Agent Tables =====================================================
      // ======================================================================
      
      case AGENT_TYPE.AGENT:
      case AGENT_TYPE.AGENT_BUSINESS_ADDRESS:
      case AGENT_TYPE.AGENT_TEAM_ASSN:
      case AGENT_TYPE.AGENT_CLIENT_ASSN:
      case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN: 
        return this.formatType()
      case AGENT_TYPE.AGENT_PROFILE: 
        return `${this.formatType()} (${this.data.profile_id})`
      case AGENT_TYPE.AGENT_SOCIAL_MEDIA:
        return `${this.data.social_media} (${this.data.url})`
      
      // === Client Tables ====================================================
      // ======================================================================

      case CLIENT_TYPE.CLIENT:
      case CLIENT_TYPE.CLIENT_ADDRESS:
      case CLIENT_TYPE.CLIENT_CONTACT:
      case CLIENT_TYPE.CLIENT_PROGRAM_CONTROL:
      case CLIENT_TYPE.CLIENT_AGENT_ASSN:
      case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
        return this.formatType()

      // === Mortgage Deal Tables =============================================
      // ======================================================================

      case MORTGAGE_DEAL_TYPE.MORTGAGE:
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL:
      case MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY:
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL:
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN:
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT:
        return this.formatType()

      // === Valid Tables =====================================================
      // ======================================================================

      case VALID_TABLES.AGENT_TEAM: return this.data.team_name
      case VALID_TABLES.AGENT_PROFILE: return this.data.profile_id
      case VALID_TABLES.COMMENT_TYPE: return this.data.comment_type
      case VALID_TABLES.LANGUAGE: return this.data.language
      case VALID_TABLES.MORTGAGE_DEAL_STATUS: return this.data.status
      case VALID_TABLES.PROGRAM: return this.data.program
      case VALID_TABLES.PROGRAM_ELEMENT: return this.data.program_element
      case VALID_TABLES.PROGRAM_ELEMENT_FREQUENCY: return this.data.frequency
      case VALID_TABLES.PROVINCE: return this.data.province
      case VALID_TABLES.SOCIAL_MEDIA: return this.data.social_media
    }   
  }

  formatAction () {
    switch(this.action) {
      case ACTIONS.CREATE: return 'created'
      case ACTIONS.DELETE: return 'deleted'
      case ACTIONS.UPDATE: return 'updated'
      case ACTIONS.REFRESH: return 'refresh'
      case ACTIONS.FILE_UPLOAD: return 'file uploaded'
    }
  }
  
  /**
   * Formats a type into a string for a message.
   * @returns String representation for the type of data in the message.
   */
  formatType () {
    switch (this.dataType) {
      // === Agent Tables =====================================================
      // ======================================================================
      case AGENT_TYPE.AGENT: return 'Agent'
      case AGENT_TYPE.AGENT_PROFILE: return 'Agent Profile'
      case AGENT_TYPE.AGENT_BUSINESS_ADDRESS: return 'Business Address'
      case AGENT_TYPE.AGENT_SOCIAL_MEDIA: return 'Social Media'
      case AGENT_TYPE.AGENT_TEAM_ASSN: return 'Team Association'
      case AGENT_TYPE.AGENT_CLIENT_ASSN: return 'Client Association'
      case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN: return 'Mortgage Deal Association'

      // === Client Tables ====================================================
      // ======================================================================
      case CLIENT_TYPE.CLIENT: return 'Client'
      case CLIENT_TYPE.CLIENT_ADDRESS: return 'Address'
      case CLIENT_TYPE.CLIENT_CONTACT: return 'Contact Information'
      case CLIENT_TYPE.CLIENT_PROGRAM_CONTROL: return 'Program Control'
      case CLIENT_TYPE.CLIENT_AGENT_ASSN: return 'Agent Association'
      case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN: return 'Mortgage Deal Association'

      // === Mortgage Deal Tables =============================================
      // ======================================================================

      case MORTGAGE_DEAL_TYPE.MORTGAGE: return 'Mortgage'
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL: return 'Mortgage Deal'
      case MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY: return 'Property'
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN: return 'Agent Association'
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN: return 'Client Association'
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT: return 'Mortgage Deal Comment'
      case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL: return 'Mortgage Deal Program Control'
    }
  }

  getText () {
    let text = null
    switch (this.action) {
      case ACTIONS.REFRESH:
        if (this.type === MESSAGE_TYPE.SUCCESS) {
          text = 'Data has been refreshed.'
        } else if (this.type === MESSAGE_TYPE.ERROR) {
          text = 'Could not refresh data.'
        }
        break
      case ACTIONS.DETAIL: case ACTIONS.LIST:
        if (this.type === MESSAGE_TYPE.SUCCESS) {
          text = `Retrieved ${this.formatType()} data.`
        } else if (this.type === MESSAGE_TYPE.ERROR) {
          text = `Could not retrieve ${this.formatType()} data.`
        }
        break
      default:
        if (this.type === MESSAGE_TYPE.SUCCESS) {
          text = `${this.formatData()} ${this.formatAction()} successfully.`
        } else if (this.type === MESSAGE_TYPE.ERROR) {
          text = `${this.formatData()} ${this.formatAction()} unsuccessfully. ${transformError(this.error)}`
        }
    }
    return text
  }

  getMessage () {
    return {
      color: this.type,
      message: {
        buttonColor: this.type,
        dismissable: true,
        text: (this.message) ? this.message : this.getText(),
      },
      timeout: (this.type === MESSAGE_TYPE.SUCCESS) ? DEFAULT_TIMEOUT : -1,
      transition: TRANSIITON,
    }
  }
}