import { isNil, kebabCase } from 'lodash'

import Repository from './repository'

class ValidService {
  create(endpoint, data) {
    return Repository.post(this.getURL(endpoint), data)
  }

  delete(endpoint) {
    return Repository.delete(this.getURL(endpoint))
  }

  detail(endpoint) {
    return Repository.get(this.getURL(endpoint))
  }

  list(endpoint) {
    return Repository.get(this.getURL(endpoint))
  }

  update(endpoint, data) {
    return Repository.put(this.getURL(endpoint), data)
  }

  getURL({ type, primary_key }) {
    if (isNil(type)) {
      throw new Error('type is null or undefined')
    }

    let url = `${kebabCase(type)}/`
  
    if (primary_key) {
      url += `${primary_key}/`
    }

    return url
  }
}

const validService = new ValidService()
export default validService