import FieldConfig, { FIELD_TYPES } from './FieldConfig'

/**
 * DateFieldConfig for Vuetify text fields.
 */
export default class DateFieldConfig extends FieldConfig {

  /**
   * Create a DateFieldConfig object.
   * @param {string} property Data-type property represented by the field.
   * @param {string} label Label of the field.
   * @param {string} smColumns Optional number of columns (1-12) that this field spans at the "sm" breakpoint or larger. Default is 12.
   * @param {string} mdColumns Optional number of columns (1-12) that this field spans at the "md" breakpoint or larger. Default is 12.
   * @param {string} iconPrepend Optional icon to prepend the field with. Default is null.
   */
   constructor ({ property, label, smColumns = 12, mdColumns = 12, iconPrepend = null, required, rules, }) {
    super({ fieldType: FIELD_TYPES.DATE, property, label, smColumns, mdColumns, iconPrepend, required, })
    
    this.rules = rules
  }

  getRules () {
    return this.rules
  }
}