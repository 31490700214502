import Repository from './repository'

// URLs associated with reports.
const URL = {
  REPORT: 'report',
  REPORT_TYPE: 'report-type',
  REPORT_PARAMETER: 'report-parameter',
}

class ReportService {

  /**
   * Submit a request to get a report.
   * @param {int} reportId 
   * @param {ReportParameters} parameters 
   * @returns the result-set of the report
   */
  getReport(reportId, parameters) {
    return Repository.post(`${URL.REPORT}/${reportId}/`, parameters)
  }

  /**
   * Get a list of reports.
   * @return the list
   */
  getReportsList() {
    return Repository.get(`${URL.REPORT}`)
  }

  /**
   * Get a list of report types.
   * @returns the list
   */
  getReportTypeList() {
    return Repository.get(`${URL.REPORT_TYPE}`)
  }

  /**
   * Get a list of report parameters.
   * @returns the list
   */
  getReportParameterList() {
    return Repository.get(`${URL.REPORT_PARAMETER}`)
  }

}

const reportService = new ReportService()
export default reportService