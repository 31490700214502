import { cloneDeep, isEqual, isNil, isObject } from "lodash"

import { DATA, ACTIONS, SAVED_DATA } from '../../constants'

export default class DataTransferObject {

  constructor() {
    if (this.constructor === DataTransferObject) {
      throw new TypeError("Can not construct abstract class DataTransferObject.");
    }
    this[DATA] = {}
    this[SAVED_DATA] = {}
    this[ACTIONS.EDIT] = {}
  }

  /**
   * Append data to the data object of the specified type.
   * @param {*} data 
   * @param {string} type 
   */
  appendData (data, type) {
    this[DATA][type].push(data)
  }

  /**
   * Get the data object of the specified type.
   * @param {string} type 
   * @returns Data object of type.
   */
  getData (type) {
    return this[DATA][type]
  }

  /**
   * Get edit state of the specified type.
   * @param type 
   * @returns True when the type is in the edit state, false otherwise.
   */
  getEdit (type) {
    return this[ACTIONS.EDIT][type]
  }

  /**
   * Get the value of the property for the specified type.
   * @param {string} type 
   * @returns Property of data object and type.
   */
  getProperty (type, property) {
    return this[DATA][type][property]
  }

  /**
   * Determine if the current data for the specified type is different than its saved data.
   * @param type 
   * @returns True when the data does not equal the saved data, false otherwise.
   */
  hasDataChanged (type) {
    return !isEqual(this.getData(type), this[SAVED_DATA][type])
  }

  /**
   * Prepend data to the data object of the specified type.
   * @param {*} data 
   * @param {string} type 
   */
  prependData (data, type) {
    this[DATA][type].unshift(data)
  }

  /**
   * Restore the data object for a specified type from its saved deep-copy.
   * @param {string} type 
   */
  restoreData (type) {
    this[DATA][type] = cloneDeep(this[SAVED_DATA][type])
    delete this[SAVED_DATA][type]
  }

  /**
   * Save a deep-copy of the data object for a specified type.
   * @param {string} type 
   */
  saveData (type) {
    this[SAVED_DATA][type] = cloneDeep(this.getData(type))
  }

  /**
   * Set the data object for a specified type.
   * @param {*} data 
   * @param {string} type 
   */
  setData (data, type) {
    this[DATA][type] = data
  }

  /**
   * Set the edit state of a specified type.
   * @param {boolean} value 
   * @param {string} type 
   */
  setEdit (value, type) {
    this[ACTIONS.EDIT][type] = value
  }

  /**
   * Checks if the data is an object, is not null, and is not undefined.
   * @param {*} data 
   */
  validateData (data) {
    if (isNil(data)) {
      throw 'Data cannot be null or undefined.'
    }
    if (!isObject(data)) {
      throw 'Data must be an object.'
    }
  }
}