<template>
  <v-app>
    <app-bar-mobile
      v-if="displayAppBar"
      @display-nav-bar="setNavBarState(true)"
    />

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <app-navigation-drawer v-model="displayNavBar" />
    
    <app-message />
  </v-app>
</template>

<script>  
import AppBarMobile from './components/singleton/AppBarMobile.vue'
import AppNavigationDrawer from './components/singleton/AppNavigationDrawer.vue'
import AppMessage from './components/singleton/AppMessage.vue'

export default {
  name: 'App',
  components: {
    AppBarMobile,
    AppMessage,
    AppNavigationDrawer,
  },
  data: () => ({
    displayNavBar: false,
    displayBar: false,
  }),
  computed: {
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    isMobile () {
      return this.breakpoint === 'md' || this.breakpoint === 'sm' || this.breakpoint === 'xs'
    },
    displayAppBar () {
      return this.isMobile && this.displayBar
    }
  },
  methods: {
    setNavBarState (forced = false) {
      this.displayNavBar = !this.isMobile && this.displayBar || forced
    }
  },
  watch: {
    $route: {
      // This watches the route change to detect when we're in the dashboard view or one of its
      // child-pages. When that is true, we display the app bar.
      handler: function(to, from) {
        this.displayBar = /^\/dashboard/.test(to.path) && !to.meta.disableMenus
        this.setNavBarState()
      },
      deep: true
    }
  },
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.25s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>