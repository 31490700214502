import { DIALOGS, EXPANDS, VUES, } from '../../constants'
import { TYPE as AGENT_TYPE } from '../../models/dto/Agent'
import { TYPE as CLIENT_TYPE } from '../../models/dto/Client'
import { TYPE as MORTGAGE_TYPE } from '../../models/dto/MortgageDeal'

import HelpService from '../../services/help.service'

const help = {
  namespaced: true,
  state: {
    display: false,
    text: {
      [VUES.AGENTS]: HelpService.getPageHelp(VUES.AGENTS),
      [VUES.AGENT]: HelpService.getPageHelp(VUES.AGENT),
      [EXPANDS.AGENT_VUE_AGENT]: [
        HelpService.getEditButtonHelp(AGENT_TYPE.AGENT),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.AGENT_VUE_AGENT_PROFILE]: [
        HelpService.getAddButtonHelp(AGENT_TYPE.AGENT_PROFILE),
        HelpService.getEditButtonHelp(AGENT_TYPE.AGENT_PROFILE),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
        HelpService.getDeleteButtonHelp(AGENT_TYPE.AGENT_PROFILE),
      ],
      [EXPANDS.AGENT_VUE_AGENT_ALIAS]: [
        'Aliases are used to display alternative values for certain kinds of data. Used in various forms of <br>output (reports, emails, etc.).',
        HelpService.getEditButtonHelp(MORTGAGE_TYPE.MORTGAGE_DEAL),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.AGENT_VUE_BUSINESS_ADDRESS]: [
        HelpService.getEditButtonHelp(AGENT_TYPE.AGENT_BUSINESS_ADDRESS),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.AGENT_VUE_SOCIAL_MEDIA]: [
        HelpService.getAddButtonHelp(AGENT_TYPE.AGENT_SOCIAL_MEDIA),
        HelpService.getEditButtonHelp(AGENT_TYPE.AGENT_SOCIAL_MEDIA),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
        HelpService.getDeleteButtonHelp(AGENT_TYPE.AGENT_SOCIAL_MEDIA),
      ],
      [EXPANDS.AGENT_VUE_TEAM_ASSN] : [
        HelpService.getEditButtonHelp(AGENT_TYPE.AGENT_TEAM_ASSN),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],

      [VUES.CLIENTS]: HelpService.getPageHelp(VUES.CLIENTS),
      [VUES.CLIENT]: HelpService.getPageHelp(VUES.CLIENT),
      [EXPANDS.CLIENT_VUE_ADDRESS]: [
        HelpService.getEditButtonHelp(CLIENT_TYPE.CLIENT_ADDRESS),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.CLIENT_VUE_CLIENT]: [
        HelpService.getEditButtonHelp(CLIENT_TYPE.CLIENT),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.CLIENT_VUE_CONTACT]: [
        HelpService.getEditButtonHelp(CLIENT_TYPE.CLIENT_CONTACT),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.CLIENT_VUE_PROGRAM_CONTROL]: [
        HelpService.getEditButtonHelp(CLIENT_TYPE.CLIENT_PROGRAM_CONTROL),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],

      [VUES.MORTGAGE_DEALS]: HelpService.getPageHelp(VUES.MORTGAGE_DEALS),
      [VUES.MORTGAGE_DEAL]: HelpService.getPageHelp(VUES.MORTGAGE_DEAL),
      [EXPANDS.MORTGAGE_DEAL_VUE_MORTGAGE_DEAL]: [
        HelpService.getEditButtonHelp(MORTGAGE_TYPE.MORTGAGE_DEAL),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.MORTGAGE_DEAL_VUE_MORTGAGE]: [
        HelpService.getEditButtonHelp(MORTGAGE_TYPE.MORTGAGE),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.MORTGAGE_DEAL_VUE_PROPERTY]: [
        HelpService.getEditButtonHelp(MORTGAGE_TYPE.MORTGAGE_PROPERTY),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],
      [EXPANDS.MORTGAGE_DEAL_VUE_PROGRAM_CONTROL]: [
        HelpService.getEditButtonHelp(MORTGAGE_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL),
        HelpService.getSaveButtonHelp(),
        HelpService.getCancelButtonHelp(),
      ],

      [VUES.VALID_TABLES]: HelpService.getPageHelp(VUES.VALID_TABLES),
      [DIALOGS.VALID_TABLES]: HelpService.getPageHelp(DIALOGS.VALID_TABLES),

      [VUES.LOAD_UPDATE_DATA]: HelpService.getPageHelp(VUES.LOAD_UPDATE_DATA),
      [VUES.LOAD_NEW_DATA]: HelpService.getPageHelp(VUES.LOAD_NEW_DATA),

      [VUES.REPORTS]: HelpService.getPageHelp(VUES.REPORTS),
    },
  },
  getters: {
    getText: (state) => (vue) => state.text[vue],
    isDisplaying: (state) => state.display,
  },
  mutations: {
    toggleDisplay (state) {
      state.display = !state.display
    },
  },
  actions: {
    toggleHelp ({ commit }, value) {
      commit('toggleDisplay', value)
    },
  },
}

export default help