import { filter, forEach, isArray, isEmpty, isNil, map, reject } from "lodash"

import { DATA, ICONS, SOCIAL_MEDIA_ICON_MAP, YES_OR_NO } from "../../constants"
import DataTransferObject from "./DataTransferObject"
import FieldConfigFactory from "../field_configs/FieldConfigFactory"
import ValidationRulesFactory from "../validation_rules/ValidationRulesFactory"

// Agent types.
export const TYPE = {
  AGENT: 'agent',
  AGENT_ALIAS: 'agent_alias',
  AGENT_BUSINESS_ADDRESS: 'agent_business_address',
  AGENT_CLIENT_ASSN: 'agent_client_assn',
  AGENT_MORTGAGE_DEAL_ASSN: 'agent_mortgage_deal_assn',
  AGENT_PROFILE: 'agent_profile',
  AGENT_SOCIAL_MEDIA: 'agent_social_media',
  AGENT_TEAM_ASSN: 'agent_team',
}

// Agent-type properties.
export const PROPERTY = {
  ACTIVE: 'active',
  ADDRESS: 'address',
  AGENT_NAME_ALIAS: 'agent_name_alias',
  AGENT_ID: 'agent_id',
  APPLICATION_URL: 'application_url',
  BOSS_BROKER_CODE: 'boss_broker_code',
  BROKERAGE_NUMBER: 'brokerage_number',
  CITY: 'city',
  DESIGNATION: 'designation',
  EMAIL_ADDRESS: 'email_address',
  EMAIL_SENDER_ALIAS: 'email_sender_alias',
  EMAIL_SIGNOFF_NAME_ALIAS: 'email_signoff_name_alias',
  FIRST_NAME: 'first_name',
  LEGACY_AGENT_ID: 'legacy_agent_id',
  MOBILE_NUMBER: 'mobile_number',
  MOBILE_NUMBER_ALIAS: 'mobile_number_alias',
  PHONE_EXTENSION: 'phone_extension',
  PHONE_NUMBER: 'phone_number',
  PHONE_NUMBER_ALIAS: 'phone_number_alias',
  PLURAL: 'plural',
  POSTAL_CODE: 'postal_code',
  PROFILE_ID: 'profile_id',
  PROFILE_PHOTO_FILE: 'profile_photo_file',
  PROVINCE: 'province',
  PROVINCIAL_DISCLOSURE: 'provincial_disclosure',
  SIGNATURE_PHOTO_FILE: 'signature_photo_file',
  SOCIAL_MEDIA: 'social_media',
  SURNAME: 'surname',
  TEAM_NAME: 'team_name',
  TITLE: 'title',
  URL: 'url',
  VALID_ADDRESS: 'valid_address',
  VENDOR_ID: 'vendor_id',
  WEBSITE: 'website',
}

// Default values for primary and secondary Agent data-types.
const DEFAULTS = {
  [TYPE.AGENT]: { default: true, agent_id: '', operator_id: '', dlc: '', first_name: '', surname: '', brokerage_number: '', legacy_agent_id: '', active: 'Y', vendor_id: '', boss_broker_code: '', designation: '', },
  [TYPE.AGENT_BUSINESS_ADDRESS]: { default: true, id: '', agent_id: '', operator_id: '', dlc: '', address: '', city: '', postal_code: '', valid_address: 'Y', province: 'AB', country: 'CA', },
  [TYPE.AGENT_ALIAS]: [],
  [TYPE.AGENT_CLIENT_ASSN]: [],
  [TYPE.AGENT_MORTGAGE_DEAL_ASSN]: [],
  [TYPE.AGENT_PROFILE]: [],
  [TYPE.AGENT_SOCIAL_MEDIA]: [],
  [TYPE.AGENT_TEAM_ASSN]: { default: true, id: '', operator_id: '', dlc: '', agent_id: '', team_name: '', },
}

// Defaut values for iterable Agent objects.
const DEFAULT_ITEM = {
  [TYPE.AGENT_PROFILE]: { default: true, id: '', [TYPE.AGENT_CLIENT_ASSN]: [], [TYPE.AGENT_MORTGAGE_DEAL_ASSN]: [], operator_id: '', dlc: '', title: '', phone_number: '', phone_extension: '', mobile_number: '', email_address: '', website: '', profile_photo_file: '', signature_photo_file: '', provincial_disclosure: '', application_url: '', plural: 'N', agent_id: '', profile_id: '', },
  [TYPE.AGENT_SOCIAL_MEDIA]: { default: true, id: '', operator_id: '', dlc: '', url: '', agent_id: '', social_media: '', },
  [TYPE.AGENT_ALIAS]: { default: true, id: '', agent_id: '', profile_id: '', operator_id: '', dlc: '', agent_name_alias: '', email_sender_alias: '', email_signoff_name_alias: '', phone_number_alias: '', mobile_number_alias: '', },
}

// Validation rules applied to form fields and CSV files.
export const VALIDATION_RULES = {
  [TYPE.AGENT]: {
    [PROPERTY.ACTIVE]: ValidationRulesFactory.makeYesOrNoRules(),
    [PROPERTY.AGENT_ID]: ValidationRulesFactory.makeIntRules(),
    [PROPERTY.BOSS_BROKER_CODE]: ValidationRulesFactory.makeMediumIntRules(),
    [PROPERTY.BROKERAGE_NUMBER]: ValidationRulesFactory.makeMediumIntRules(),
    [PROPERTY.DESIGNATION]: ValidationRulesFactory.makeStringRules({ length: 50, nullable: true, }),
    [PROPERTY.FIRST_NAME]: ValidationRulesFactory.makeStringRules({ length: 50, }),
    [PROPERTY.LEGACY_AGENT_ID]: ValidationRulesFactory.makeStringRules({ length: 105, }),
    [PROPERTY.SURNAME]: ValidationRulesFactory.makeStringRules({ length: 50, }),
    [PROPERTY.VENDOR_ID]: ValidationRulesFactory.makeStringRules({ length: 25, }),
  },
  [TYPE.AGENT_BUSINESS_ADDRESS]: {
    [PROPERTY.ADDRESS]: ValidationRulesFactory.makeStringRules({ length: 100, }),
    [PROPERTY.CITY]: ValidationRulesFactory.makeStringRules({ length: 80, }),
    [PROPERTY.POSTAL_CODE]: ValidationRulesFactory.makePostalCodeRules(),
    [PROPERTY.PROVINCE]: ValidationRulesFactory.makeProvinceRules(),
    [PROPERTY.VALID_ADDRESS]: ValidationRulesFactory.makeYesOrNoRules(),
  },
  [TYPE.AGENT_PROFILE]: {
    [PROPERTY.AGENT_ID]: ValidationRulesFactory.makeIntRules(),
    [PROPERTY.APPLICATION_URL]: ValidationRulesFactory.makeStringRules({ length: 255, nullable: true, }),
    [PROPERTY.EMAIL_ADDRESS]: ValidationRulesFactory.makeStringRules({ length: 255, }),
    [PROPERTY.MOBILE_NUMBER]: ValidationRulesFactory.makePhoneNumberRules({ nullable: true, }),
    [PROPERTY.PHONE_EXTENSION]: ValidationRulesFactory.makeIntRules({ nullable: true, }),
    [PROPERTY.PHONE_NUMBER]: ValidationRulesFactory.makePhoneNumberRules(),
    [PROPERTY.PLURAL]: ValidationRulesFactory.makeYesOrNoRules(),
    [PROPERTY.PROFILE_ID]: ValidationRulesFactory.makeStringRules({ length: 2, }),
    [PROPERTY.PROFILE_PHOTO_FILE]: ValidationRulesFactory.makeStringRules({ length: 50, nullable: true, }),
    [PROPERTY.PROVINCIAL_DISCLOSURE]: ValidationRulesFactory.makeStringRules({ length: 255, nullable: true, }),
    [PROPERTY.SIGNATURE_PHOTO_FILE]: ValidationRulesFactory.makeStringRules({ length: 50, nullable: true, }),
    [PROPERTY.TITLE]: ValidationRulesFactory.makeStringRules({ length: 100, nullable: true, }),
    [PROPERTY.WEBSITE]: ValidationRulesFactory.makeStringRules({ length: 100, nullable: true, }),
  },
  [TYPE.AGENT_ALIAS]: {
    [PROPERTY.AGENT_ID]: ValidationRulesFactory.makeIntRules(),
    [PROPERTY.PROFILE_ID]: ValidationRulesFactory.makeStringRules({ length: 2, }),
    [PROPERTY.AGENT_NAME_ALIAS]: ValidationRulesFactory.makeStringRules({ length: 105, nullable: true, }),
    [PROPERTY.EMAIL_SENDER_ALIAS]: ValidationRulesFactory.makeStringRules({ length: 105, nullable: true, }),
    [PROPERTY.EMAIL_SIGNOFF_NAME_ALIAS]: ValidationRulesFactory.makeStringRules({ length: 50, nullable: true, }),
    [PROPERTY.PHONE_NUMBER_ALIAS]: ValidationRulesFactory.makeStringRules({ length: 40, nullable: true, }),
    [PROPERTY.MOBILE_NUMBER_ALIAS]: ValidationRulesFactory.makeStringRules({ length: 20, nullable: true, }),
  },
  [TYPE.AGENT_SOCIAL_MEDIA]: {
    [PROPERTY.URL]: ValidationRulesFactory.makeUrlRules({ length: 150, }),
  },
  [TYPE.AGENT_TEAM_ASSN]: {
    [PROPERTY.TEAM_NAME]: ValidationRulesFactory.makeStringRules({ length: 50, }),
  },
}

/**
 * Get the length rule value from a validation rule.
 * @param {string} type Agent.TYPE
 * @param {*} property Agent.PROPERTY
 * @returns Length rule value.
 */
const getLength = (type, property) => {
  if ('length' in VALIDATION_RULES[type][property]) {
    return VALIDATION_RULES[type][property].length
  }
  throw new ReferenceError(`Rule for ${type}.${property} does not have a 'length' property.`)
}

/**
 * Get rules array from a validation rule.
 * @param {string} type Agent.TYPE
 * @param {*} property Agent.PROPERTY
 * @returns Length rule value.
 */
const getRules = (type, property) => {
  if ('rules' in VALIDATION_RULES[type][property]) {
    return VALIDATION_RULES[type][property].rules
  }
  throw new ReferenceError(`Rule for ${type}.${property} does not have a 'rules' property.`)
}

/**
 * Get specific rules for a text field config.
 * @param {string} type Agent.TYPE
 * @param {*} property Agent.PROPERTY
 * @returns Object containing the counter and rules for a text field.
 */
const getTextRules = (type, property) => {
  return {
    property,
    counter: getLength(type, property),
    rules: getRules(type, property),
  }
}

/**
* Determine if members of the specified type are iterable.
* @param {string} type Agent.TYPE 
* @returns True if the data-type is iterable, false if the datat-type is a single object.
*/
export const isTypeIterable = (type) => Array.isArray(DEFAULTS[type])

// Agent field metadata.
const FIELD_METADATA = {
  [TYPE.AGENT]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT, PROPERTY.AGENT_ID), label: 'Agent ID', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT, PROPERTY.FIRST_NAME), smColumns: 5, mdColumns: 5, label: 'First Name', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT, PROPERTY.SURNAME), smColumns: 5, mdColumns: 5, label: 'Surname', }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 2, mdColumns: 2, label: 'Is agent active?', property: PROPERTY.ACTIVE, items: YES_OR_NO, }),

        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT, PROPERTY.BROKERAGE_NUMBER), smColumns: 6, mdColumns: 3, label: 'Brokerage Number', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT, PROPERTY.BOSS_BROKER_CODE), smColumns: 6, mdColumns: 3, label: 'BOSS Broker Code', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT, PROPERTY.DESIGNATION), mdColumns: 6, label: 'Designation', required: false, }),

        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT, PROPERTY.VENDOR_ID), smColumns: 5, mdColumns: 3, label: 'Vendor ID', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT, PROPERTY.LEGACY_AGENT_ID), smColumns: 7, mdColumns: 9, label: 'Legacy Agent ID', }),
      ],
    ],
  },
  [TYPE.AGENT_BUSINESS_ADDRESS]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_BUSINESS_ADDRESS, PROPERTY.ADDRESS), label: 'Address', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_BUSINESS_ADDRESS, PROPERTY.CITY), smColumns: 6, mdColumns: 4, label: 'City', }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 3, mdColumns: 2, label: 'Province', property: PROPERTY.PROVINCE, textProperty: PROPERTY.PROVINCE, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_BUSINESS_ADDRESS, PROPERTY.POSTAL_CODE), smColumns: 3, mdColumns: 2, label: 'Postal Code', }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 3, mdColumns: 2, label: 'Is address valid?', property: PROPERTY.VALID_ADDRESS, items: YES_OR_NO, }),
      ],
    ],
  },
  [TYPE.AGENT_PROFILE]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.TITLE), mdColumns: 6, label: 'Title', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.WEBSITE), mdColumns: 6, label: 'Website', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.APPLICATION_URL), mdColumns: 12, label: 'Application URL', required: false, }),

        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.PHONE_NUMBER), smColumns: 6, mdColumns: 5, label: 'Phone Number', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.PHONE_EXTENSION), smColumns: 6, mdColumns: 5, label: 'Phone Extension', required: false, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 6, mdColumns: 2, label: 'Plural?', property: PROPERTY.PLURAL, items: YES_OR_NO, }),
        
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.MOBILE_NUMBER), mdColumns: 6, label: 'Mobile Number', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.EMAIL_ADDRESS), mdColumns: 6, label: 'Email Address', }),

        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.PROVINCIAL_DISCLOSURE), label: 'Provincial Disclosure', required: false, }),

        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.PROFILE_PHOTO_FILE), mdColumns: 6, label: 'Profile Photo (filename)', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_PROFILE, PROPERTY.SIGNATURE_PHOTO_FILE), mdColumns: 6, label: 'Signature Photo (filename)', required: false, }),
      ],
    ],
    soloFields: {
      [PROPERTY.PROFILE_ID]: FieldConfigFactory.makeSelectFieldConfig({ label: 'Agent Profile', property: PROPERTY.PROFILE_ID, textProperty: PROPERTY.PROFILE_ID, iconPrepend:ICONS.AGENT_BOX, }), 
    },
  },
  [TYPE.AGENT_ALIAS]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_ALIAS, PROPERTY.AGENT_NAME_ALIAS), mdColumns: 12, label: 'Agent Name Alias', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_ALIAS, PROPERTY.EMAIL_SENDER_ALIAS), mdColumns: 12, label: 'Email Sender Alias', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_ALIAS, PROPERTY.EMAIL_SIGNOFF_NAME_ALIAS), mdColumns: 12, label: 'Email Sign-off Alias', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_ALIAS, PROPERTY.PHONE_NUMBER_ALIAS), mdColumns: 6, label: 'Phone Number Alias', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_ALIAS, PROPERTY.MOBILE_NUMBER_ALIAS), mdColumns: 6, label: 'Mobile Number Alias', required: false, }),
      ],
    ],
    soloFields: {
      [PROPERTY.PROFILE_ID]: FieldConfigFactory.makeSelectFieldConfig({ label: 'Agent Profile', property: PROPERTY.PROFILE_ID, textProperty: PROPERTY.PROFILE_ID, iconPrepend:ICONS.AGENT_BOX, }), 
    },
  },
  [TYPE.AGENT_SOCIAL_MEDIA]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.AGENT_SOCIAL_MEDIA, PROPERTY.URL), label: 'URL', }),
      ],
    ],
    soloFields: {
      [PROPERTY.SOCIAL_MEDIA]: FieldConfigFactory.makeSelectFieldConfig({ label: 'Social Media', property: PROPERTY.SOCIAL_MEDIA, textProperty: PROPERTY.SOCIAL_MEDIA, }), 
    },
  },
  [TYPE.AGENT_TEAM_ASSN]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeSelectFieldConfig({ label: 'Team', property: PROPERTY.TEAM_NAME, textProperty: PROPERTY.TEAM_NAME, }), 
      ],
    ],
  },
}

/**
 * Set valid data for select fields.
 * @param {*} object An object containing arrays of valid data.
 */
export const setSelectValidData = ({ provinces = null, agentTeams = null, socialMedia = null, agentProfiles = null,  }) => {
  if (provinces) {
    FIELD_METADATA[TYPE.AGENT_BUSINESS_ADDRESS].fieldGroups[0][2].items = provinces
  }
  if (agentTeams) {
    FIELD_METADATA[TYPE.AGENT_TEAM_ASSN].fieldGroups[0][0].items = agentTeams
  }
  if (socialMedia) {
    FIELD_METADATA[TYPE.AGENT_SOCIAL_MEDIA].soloFields[PROPERTY.SOCIAL_MEDIA].items = socialMedia
  }
  if (agentProfiles) {
    FIELD_METADATA[TYPE.AGENT_PROFILE].soloFields[PROPERTY.PROFILE_ID].items = agentProfiles
  }
}

export default class Agent extends DataTransferObject {
  /**
   * Create a Agent from a DTO.
   * @param {*} dto DTO containing the primary and secondary data types of a Agent.
   */
  constructor (dto) {
    super()

    if (!isNil(dto)) {
      this.setAddressData(dto[TYPE.AGENT_BUSINESS_ADDRESS])
      this.setAgentData(dto[TYPE.AGENT])
      this.setAliasData(dto[TYPE.AGENT_ALIAS])
      this.setProfileData(dto[TYPE.AGENT_PROFILE])
      this.setSocialMediaData(dto[TYPE.AGENT_SOCIAL_MEDIA])
      this.setTeamData(dto[TYPE.AGENT_TEAM_ASSN])

      super.setEdit(false, TYPE.AGENT)
      super.setEdit(false, TYPE.AGENT_ALIAS)
      super.setEdit(false, TYPE.AGENT_BUSINESS_ADDRESS)
      super.setEdit(false, TYPE.AGENT_PROFILE)
      super.setEdit(false, TYPE.AGENT_TEAM_ASSN)
      super.setEdit(false, TYPE.AGENT_SOCIAL_MEDIA)
    }

    this.metadata = FIELD_METADATA
  }

  /**
   * Append data to the specified type.
   * @param {*} data 
   * @param {string} type Agent.TYPE
   * @param {number} index Index into an iterable Agent.TYPE
   */
   appendData (data, type, index) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`Agent data-type '${type}' is not iterable.`)
    }

    switch (type) {
      case TYPE.AGENT_CLIENT_ASSN:
        this[DATA][TYPE.AGENT_PROFILE][index][TYPE.AGENT_CLIENT_ASSN].push(data)
        break

      case TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        this[DATA][TYPE.AGENT_PROFILE][index][TYPE.AGENT_MORTGAGE_DEAL_ASSN].push(data)
        break

      default:
        super.appendData(data, type)
    }
  }

  /**
   * Disable editing of this Agent and restore the type's data object to a pre-edit state.
   * @param {string} type Agent.TYPE
   */
  cancelEdit (type) {
    this.disableEdit(type)
    super.restoreData(type)
  }

  /**
   * Delete a member item from the specified iterable type.
   * @param {*} item Item to delete.
   * @param {string} type Agent.TYPE
   * @param {number} index Index into an iterable Agent.TYPE
   */
  deleteData (item, type, index)  {
    if (!isTypeIterable(type)) {
      throw new TypeError(`Agent data-type '${type}' is not iterable.`)
    }

    let remainingData = null

    switch (type) {
      case TYPE.AGENT_PROFILE:
        if (!item.agent_id || !item.id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, id].`)
        }
        remainingData = reject(super.getData(type), { agent_id: item.agent_id, id: item.id, })
        this.setProfileData(remainingData)
        break

      case TYPE.AGENT_SOCIAL_MEDIA:
        if (!item.agent_id || !item.id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, id].`)
        }
        remainingData = reject(super.getData(type), { agent_id: item.agent_id, id: item.id, })
        this.setSocialMediaData(remainingData)
        break

      case TYPE.AGENT_CLIENT_ASSN:
        if (!item.agent_id || !item.profile_id || !item.client_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, profile_id, client_id].`)
        }
        this[DATA][TYPE.AGENT_PROFILE][index][TYPE.AGENT_CLIENT_ASSN] = reject(
          this[DATA][TYPE.AGENT_PROFILE][index][TYPE.AGENT_CLIENT_ASSN],
          { agent_id: item.agent_id, profile_id: item.profile_id, client_id: item.client_id, }
        )
        break

      case TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        if (!item.mortgage_deal_id || !item.agent_id || !item.profile_id ) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, profile_id, mortgage_deal_id,].`)
        }
        this[DATA][TYPE.AGENT_PROFILE][index][TYPE.AGENT_MORTGAGE_DEAL_ASSN] = reject(
          this[DATA][TYPE.AGENT_PROFILE][index][TYPE.AGENT_MORTGAGE_DEAL_ASSN],
          { mortgage_deal_id: item.mortgage_deal_id, agent_id: item.agent_id, profile_id: item.profile_id, }
        )
        break

      default:
        throw new TypeError(`Agent data-type '${type}' does not have member item deletion implemented.`)
    }
  }
  
  /**
   * Disable editing of the specified type.
   * @param {string} type Agent.TYPE
   */
  disableEdit (type) {
    super.setEdit(false, type)
  }

  /**
   * Enable editing of the specified type and save the state of the type's data object.
   * @param {string} type Agent.TYPE
   */
  enableEdit (type) {
    super.saveData(type)
    super.setEdit(true, type)
  }

  /**
   * Determine if a specific type is being edited.
   * @param {string} type Agent.TYPE
   * @returns True if that type is editing, false otherwise.
   */
  isEditing (type) {
    return super.getEdit(type)
  }

  getAddressProperty (property) {
    return super.getProperty(TYPE.AGENT_BUSINESS_ADDRESS, property)
  }

  getAllAliases () {
    return super.getData(TYPE.AGENT_ALIAS)
  }

  getAllProfiles () {
    return super.getData(TYPE.AGENT_PROFILE)
  }

  getAllSocialMedia () {
    return super.getData(TYPE.AGENT_SOCIAL_MEDIA)
  }

  getAllTeams () {
    return super.getData(TYPE.AGENT_TEAM_ASSN)
  }

  getAgentProperty (property) {
    return super.getProperty(TYPE.AGENT, property)
  }

  getAgentTeamProperty (property) {
    return super.getProperty(TYPE.AGENT_TEAM_ASSN, property)
  }

  getClientAssociationCount (index) {
    if (super.getData(TYPE.AGENT_PROFILE)[index][TYPE.AGENT_CLIENT_ASSN]) {
      return super.getData(TYPE.AGENT_PROFILE)[index][TYPE.AGENT_CLIENT_ASSN].length
    }
    else {
      return 0
    }
  }

  getDefault(type) {
    return DEFAULTS[type]
  }

  getDefaultItem(type) {
    return DEFAULT_ITEM[type]
  }

  /**
   * Get the field groups of the specified type.
   * @param {string} type Agent.TYPE
   * @returns Array of field groups object.
   */
  getFieldGroups (type) {
    return this.metadata[type].fieldGroups
  }

  /**
   * Get the field groups titles of the specified type.
   * @param {string} type Agent.TYPE
   * @returns Array of field group title strings.
   */
  getFieldGroupTitles (type) {
    return this.metadata[type].fieldGroupTitles
  }

  /**
   * Get the member from the specified iterable type that equals the item.
   * @param {string} type An iterable Agent.TYPE
   * @param {*} item An object of specified-type.
   * @returns Data object of iterable type.
   */
  getIterableData (type, item) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`Agent data-type '${type}' is not iterable. Use Agent.getData() instead.`)
    }

    switch (type) {
      case TYPE.AGENT_CLIENT_ASSN:
        if (!item.agent_id || !item.profile_id || !item.client_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, profile_id, client_id].`)
        }
        return filter(super.getData(type), { agent_id: item.agent_id, profile_id: item.profile_id, client_id: item.client_id, })

      case TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        if (!item.mortgage_deal_id || !item.agent_id || !item.profile_id ) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, profile_id, mortgage_deal_id,].`)
        }
        return filter(super.getData(type), { mortgage_deal_id: item.mortgage_deal_id, agent_id: item.agent_id, profile_id: item.profile_id, })

      default:
        throw new TypeError(`Agent data-type '${type}' does not have getIterableData() implemented.`)
    }
  }

  getMortgageDealAssociationCount (index) {
    if (super.getData(TYPE.AGENT_PROFILE)[index][TYPE.AGENT_MORTGAGE_DEAL_ASSN]) {
      return super.getData(TYPE.AGENT_PROFILE)[index][TYPE.AGENT_MORTGAGE_DEAL_ASSN].length
    }
    else {
      return 0
    }
  }

  /**
   * Name of the Agent.
   * @returns First name and surname of the Agent.
   */
  getName () {
    return `${this.getAgentProperty(PROPERTY.FIRST_NAME)} ${this.getAgentProperty(PROPERTY.SURNAME)}`
  }

  /**
   * Create an array of field objects out of agent social media data.
   * @returns Array of social media fields containing both the data and field metadata.
   */
  getSocialMediaFields () {
    const urlField = this.getFieldGroups(TYPE.AGENT_SOCIAL_MEDIA)[0][0]

    let fields = []

    forEach(this.getAllSocialMedia(), data => {
      fields.push({
        ...urlField,
        iconPrepend: SOCIAL_MEDIA_ICON_MAP[data.social_media],
        label: `${data.social_media} URL`,
      })
    })

    return fields 
  }

  /**
   * Get the solo-fields of the specified type.
   * @param {string} type Agent.TYPE
   * @returns Array of solo-field objects.
   */
  getSoloFields (type) {
    return this.metadata[type].soloFields
  }

  /**
   * Get a string containing a summary of a Agent data-type.
   * @param {string} type Agent.TYPE
   * @returns A summary of that data-type.
   */
  getSummaryText (type) {
    let count = 0

    switch(type) {
      case TYPE.AGENT:
        return `${this.getName()} ∙ ${this.getAgentProperty(PROPERTY.BROKERAGE_NUMBER)}`

      case TYPE.AGENT_ALIAS:
        return ''

      case TYPE.AGENT_BUSINESS_ADDRESS:
        const address = `${this.getAddressProperty(PROPERTY.ADDRESS)}, ${this.getAddressProperty(PROPERTY.CITY)} ∙ ${this.getAddressProperty(PROPERTY.PROVINCE)} ∙ ${this.getAddressProperty(PROPERTY.POSTAL_CODE)}`
        return this.hasAddress() ? address : 'No address information.'

      case TYPE.AGENT_PROFILE:
        return map(this.getAllProfiles(), profile => profile.profile_id).join(', ')

      case TYPE.AGENT_TEAM_ASSN:
        return this.getAgentTeamProperty(PROPERTY.TEAM_NAME)

      case TYPE.AGENT_CLIENT_ASSN:
        forEach(this.getAllProfiles(), profile => {
          if (profile[TYPE.AGENT_CLIENT_ASSN]) {
            count += profile[TYPE.AGENT_CLIENT_ASSN].length
          }
        })

        return `${count}`

      case TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        forEach(this.getAllProfiles(), profile => {
          if (profile[TYPE.AGENT_MORTGAGE_DEAL_ASSN]) {
            count += profile[TYPE.AGENT_MORTGAGE_DEAL_ASSN].length
          }
        })

        return `${count}`
    }
  }

  /**
   * Checks if the Agent has an address data object.
   * @returns True if the address is non-empty, false otherwise.
   */
  hasAddress () {
    return !isEmpty(super.getData(TYPE.AGENT_BUSINESS_ADDRESS))
  }

  /**
   * Checks if the Agent has a atleast one social media object.
   * @returns True if social media are non-empty, false otherwise.
   */
  hasSocialMedia () {
    return this.getAllSocialMedia().length > 0
  }

  /**
   * Checks if the Agent has a atleast one team object.
   * @returns True if teams are non-empty, false otherwise.
   */
  hasTeams () {
    return this.getAllTeams().length > 0
  }

  /**
   * Sets the address data for this Agent. Converts the data into a single object if wrapped in an array.
   * @param {*} data 
   */
  setAddressData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.AGENT_BUSINESS_ADDRESS]
    }
    super.setData(data, TYPE.AGENT_BUSINESS_ADDRESS)
  }

  /**
   * Sets the agent data for this Agent.
   * @param {*} data 
   */
  setAgentData (data) {
    super.validateData(data)
    super.setData(data, TYPE.AGENT)
  }

  /**
   * Sets the alias data for this Agent.
   * @param {*} data 
   */
  setAliasData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.AGENT_ALIAS]
    }
    super.setData(data, TYPE.AGENT_ALIAS)
  }

  /**
   * Sets the client association data for this Agent.
   * @param {*} data 
   */
  setClientAssnData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.AGENT_CLIENT_ASSN]
    }
    super.setData(data, TYPE.AGENT_CLIENT_ASSN)
  }

  /**
   * Sets the profile data for this Agent.
   * @param {*} data 
   */
  setProfileData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.AGENT_PROFILE]
    }
    super.setData(data, TYPE.AGENT_PROFILE)
  }

  /**
   * Sets the social media data for this Agent.
   * @param {*} data 
   */
  setSocialMediaData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.AGENT_SOCIAL_MEDIA]
    }
    super.setData(data, TYPE.AGENT_SOCIAL_MEDIA)
  }

  /**
   * Sets the team data for this Agent.
   * @param {*} data 
   */
  setTeamData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.AGENT_TEAM_ASSN]
    }
    super.setData(data, TYPE.AGENT_TEAM_ASSN)
  }

  /**
   * Set the data object for a specified type.
   * @override DataTransferObject.setData()
   * @param {*} data 
   * @param {string} type Agent.TYPE
   */
  setData(data, type) {
    switch (type) {
      case TYPE.AGENT:
        this.setAgentData(data)
        break
      case TYPE.AGENT_ALIAS:
        this.setAliasData(data)
        break
      case TYPE.AGENT_BUSINESS_ADDRESS:
        this.setAddressData(data)
        break
      case TYPE.AGENT_CLIENT_ASSN:
        this.setClientAssnData(data)
        break
      case TYPE.AGENT_MORTGAGE_DEAL_ASSN:
        this.setMortgageDealAssnData(data)
        break
      case TYPE.AGENT_PROFILE:
        this.setProfileData(data)
        break
      case TYPE.AGENT_SOCIAL_MEDIA:
        this.setSocialMediaData(data)
        break
      case TYPE.AGENT_TEAM_ASSN:
        this.setTeamData(data)
        break
    }
  }

  /**
   * Sets the mortgage deal association data for this Agent.
   * @param {*} data 
   */
  setMortgageDealAssnData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.AGENT_MORTGAGE_DEAL_ASSN]
    }
    super.setData(data, TYPE.AGENT_MORTGAGE_DEAL_ASSN)
  }

  /**
   * Sets the specified type to its default value.
   * @param {string} type Agent.TYPE
   */
  setToDefaultData(type) {
    super.setData(DEFAULTS[type], type)
  }
}