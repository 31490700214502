import axios from 'axios'

// const BASE_URL = 'http://127.0.0.1:8000/api/v1/' // DEV
const BASE_URL = 'https://api.invismi.com/api/v1/' // PROD

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
})

const processError = error => {
  return Promise.reject(error)
}

const responseBody = (response) => {
  return Promise.resolve(response.data)
}

const Repository = {
  api,
  get: (url, params = {}) => api.get(url, { params, }).then(response => responseBody(response)).catch(error => processError(error)),
  post: (url, data) => api.post(url, data).then(response => responseBody(response)).catch(error => processError(error)),
  put: (url, data) => api.put(url, data).then(response => responseBody(response)).catch(error => processError(error)),
  delete: (url) => api.delete(url).then(response => responseBody(response)).catch(error => processError(error)),
  
  postWithFile: async (url, data, callback) => {
    let config = {
      headers: {'Content-type': 'multipart/form-data', },
      onUploadProgress: callback,
    }
    return api.post(url, data, config)
      .then(response => responseBody(response))
      .catch(error => processError(error))
  },
}

export default Repository