import { cloneDeep, forEach, isNil } from 'lodash'
import { VALID_TABLES } from '../../constants'
import MortgageDeal, { setSelectValidData, TYPE } from '../../models/dto/MortgageDeal'

import MortgageDealService from '../../services/api/mortgage.deal.service'

const mortgageDeal = {
  namespaced: true,
  state: {
    basicListCache: [],    // List containing basic information of all mortgage deals (mortgage_deal_id, loan_code).
    mortgageDealCache: {}, // Cached mortgage deals from the current session.
  },
  getters: {
    getCachedMortgageDeal: state => mortgage_deal_id => cloneDeep(state.mortgageDealCache[mortgage_deal_id]),
    getCachedBasicList: state => () => state.basicListCache,
    hasCachedBasicList: state => () => state.basicListCache.length > 0,
    isMortgageDealCached: state => mortgage_deal_id => !isNil(state.mortgageDealCache[mortgage_deal_id]), 
  },
  mutations: {
    cacheMortgageDeal (state, { mortgage_deal, mortgage_deal_id }) {
      state.mortgageDealCache[mortgage_deal_id] = cloneDeep(mortgage_deal)
    },
    cacheBasicList (state, list) {
      state.basicListCache = cloneDeep(list)
    },
    clearCache (state) {
      state.mortgageDealCache = {}
    },
  },
  actions: {
    createData ({ commit }, { type, payload }) {
      const endpointConfig = {
        type,
        mortgage_deal_id: payload.mortgage_deal_id,
      }

      return MortgageDealService.create(endpointConfig, payload)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    createFullMortgageDeal ({ commit }, { payload }) {
      return MortgageDealService.createFull(payload)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    deleteData ({ commit }, { type, mortgage_deal_id, id }) {
      const endpointConfig = { type, mortgage_deal_id, id, }

      return MortgageDealService.delete(endpointConfig)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    fetchBasicList ({ commit, getters }, { force = false }) {
      // If the basic list is cached or we're not forcing the fetch, return the cached basic list.
      if (getters.hasCachedBasicList() && !force) {
        return getters.getCachedBasicList()
      }

      return MortgageDealService.basicList()
        .then(list => {
          let normalizedList = []

          forEach(list, mortgage_deal => {
            normalizedList.push({
              'mortgage_deal_id': mortgage_deal.mortgage_deal_id,
              'loan_code': mortgage_deal.mortgage_id__loan_code,
            })
          })

          commit('cacheBasicList', normalizedList)
          return normalizedList
        })
        .catch(error => { throw error })
    },

    fetchDetail ({ commit }, { type, mortgage_deal_id, mortgage_id, id }) {
      const endpointConfig = { type, mortgage_deal_id, mortgage_id, id, }

      return MortgageDealService.detail(endpointConfig)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    fetchList ({ commit }, { type, mortgage_deal_id, mortgage_id }) {
      const endpointConfig = { type, mortgage_deal_id, mortgage_id, }

      return MortgageDealService.list(endpointConfig)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },

    async fetchMortgageDeal ({ dispatch, commit, getters }, { mortgage_deal_id, force = false }) {
      // If the MortgageDeal is cached or we're not forcing the fetch, return the cached MortgageDeal.
      if (getters.isMortgageDealCached(mortgage_deal_id) && !force) {
        return getters.getCachedMortgageDeal(mortgage_deal_id)
      }
      // Otherwise, fetch it, cache the new MortgageDeal, and return it to the caller.
  
      // Fetch valid table dependencies and pass them to MortgageDeal.
      try {
        let validData = await Promise.all([
          dispatch('valid/fetchList', { type: VALID_TABLES.PROVINCE, }, { root: true, }),
          dispatch('valid/fetchList', { type: VALID_TABLES.MORTGAGE_DEAL_STATUS, }, { root: true, }),
          dispatch('valid/fetchList', { type: VALID_TABLES.PROGRAM, }, { root: true, }),
          dispatch('valid/fetchList', { type: VALID_TABLES.LANGUAGE, }, { root: true, }),
          dispatch('valid/fetchList', { type: VALID_TABLES.COMMENT_TYPE, }, { root: true, }),
        ])

        setSelectValidData({
          provinces: validData[0],
          statuses: validData[1],
          programs: validData[2],
          languages: validData[3],
          commentTypes: validData[4],
        })
      }
      catch(error) {
        throw `Error fetching valid table data dependencies: ${error}`
      }

      let data = {};
      return dispatch('fetchDetail', { type: TYPE.MORTGAGE_DEAL, mortgage_deal_id, })
        .then(primaryData => {
          // Store primary MortgageDeal data.
          data[TYPE.MORTGAGE_DEAL] = primaryData

          return primaryData
        })
        .then(async (primaryData) => {
          // Store secondary MortgageDeal data.
          try {
            const secondaryData = await Promise.all([
              dispatch('fetchList', { type: TYPE.MORTGAGE, mortgage_id: primaryData.mortgage_id, }),
              dispatch('fetchList', { type: TYPE.MORTGAGE_PROPERTY, mortgage_id: primaryData.mortgage_id, }),
              dispatch('fetchList', { type: TYPE.MORTGAGE_DEAL_COMMENT, mortgage_deal_id, }),
              dispatch('fetchList', { type: TYPE.MORTGAGE_DEAL_AGENT_ASSN, mortgage_deal_id, }),
              dispatch('fetchList', { type: TYPE.MORTGAGE_DEAL_CLIENT_ASSN, mortgage_deal_id, }),
              dispatch('fetchList', { type: TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, mortgage_deal_id, }),
            ])

            data[TYPE.MORTGAGE] = secondaryData[0];
            data[TYPE.MORTGAGE_PROPERTY] = secondaryData[1];
            data[TYPE.MORTGAGE_DEAL_COMMENT] = secondaryData[2];
            data[TYPE.MORTGAGE_DEAL_AGENT_ASSN] = secondaryData[3];
            data[TYPE.MORTGAGE_DEAL_CLIENT_ASSN] = secondaryData[4];
            data[TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL] = secondaryData[5];
          }
          catch(error) {
            throw `Error fetching secondary data: ${error}`
          }

          return data
        })
        .then(data => {
          // Create, cache, and return the MortgageDeal.
          let mortgageDeal = new MortgageDeal(data)
          commit('cacheMortgageDeal', { mortgage_deal: mortgageDeal, mortgage_deal_id, })

          return mortgageDeal
        })
        .catch(error => {
          if (error.status_code !== 404) {
            throw 'Error fetching MortgageDeal object: ' + error
          }

          return null
        })
    },

    updateData ({ commit }, { type, payload }) {
      const endpointConfig = {
        type,
        mortgage_deal_id: payload.mortgage_deal_id,
        mortgage_id: payload.mortgage_id,
        id: payload.id ? payload.id : null,
      }

      return MortgageDealService.update(endpointConfig, payload)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },
  },
}

export default mortgageDeal