import ValidationRulesConfig, { RULES_TYPES } from "./ValidationRulesConfig"

/**
 * Append rule specifications to the ValidationRulesConfig.
 * @param {*} config Object containing the ValidationRulesConfig and any additional specifications for the config.
 * @returns The ValidationRulesConfig with the specifications appended to it.
 */
const appendRules = ({ config, length = null, nullable = null, format = null, regexp = null, primaryKey = null }) => {
  if (length) {
    config.length(length)
  }
  if (nullable) {
    config.nullable()
  }
  if (format) {
    config.format(format)
  }
  if (regexp) {
    config.regexp(regexp)
  }
  if (primaryKey) {
    config.primaryKey()
  }
  return config
}

/**
 * Create a validation rule set for use in Vuetify components that support "rules" props.
 * @param {ValidationRulesConfig} config ValidationRulesConfig
 * @returns An array of validation functions.
 */
const makeRules = (config) => {
  const conf = config.getConfig()

  let rules = []

  const required = config.hasRequiredConstraint()

  if (required) {
    rules.push(v => !!v || 'Value is required.')
  }

  if (config.hasLengthConstraint()) {
    const hasLengthConstraintMessage = `Must have ${conf.length} characters or fewer.`
    
    if (required) {
      rules.push(v => v && v.toString().length <= conf.length || hasLengthConstraintMessage)
    }
    else {
      rules.push(v => !v || v.toString().length <= conf.length || hasLengthConstraintMessage)
    }
  }

  if (config.isStringType()) {
    if (config.hasExactlyConstraint()) {
      const hasExactlyConstraintMessage = `Must have exactly ${config.exactly} characters.`

      if (required) {
        rules.push(v => v && v.length === config.exactly || hasExactlyConstraintMessage)
      }
      else {
        rules.push(v => !v || v.length === config.exactly || hasExactlyConstraintMessage)
      }
    }

    if (config.hasFormatConstraint()) {
      const hasFormatConstraintMessage = `Invalid format. Requires: ${config.getFormat()}`
      const regexp = config.getFormatRegexp()

      if (required) {
        rules.push(v => v && regexp.test(v) || hasFormatConstraintMessage)
      }
      else {
        rules.push(v => !v || regexp.test(v) || hasFormatConstraintMessage)
      }
    }
  }
  else if (config.isNumericType()) {
    if (config.hasNumericRangeConstraint()) {
      const max = config.getMax()
      const min = config.getMin()
      const hasNumericRangeConstraintMessage = `Value must be between ${min} and ${max}.`

      if (required) {
        rules.push(v => (v && v >= min && v <= max) || hasNumericRangeConstraintMessage)
      }
      else {
        rules.push(v => (!v || v >= min && v <= max) || hasNumericRangeConstraintMessage)
      }
    }

    if (config.isIntType()) {
      rules.push(v => !v || new RegExp('^[0-9]+$').test(v) || 'Value must be a whole number.')
    }
    else if (config.isDecimalType()) {
      rules.push(v=> !v || new RegExp('^[0-9]+(\.[0-9][0-9]?)?$').test(v) || 'Value must be numeric with at most two decimal places.')
    }
  }

  return rules
}

/**
 * Factory for creating ValidationRulesConfig.
 */
class ValidationRulesFactory {

  constructor () {}

  /**
   * Make validation rules for a date property.
   * @param {*} specifications Additional values to overwrite rule defaults.
   * @returns An object containing the rules.
   */
  makeDateRules({ nullable = null } = {}) {
    let config = new ValidationRulesConfig(RULES_TYPES.DATE)
    config = appendRules({ config, nullable, })
    return { rules: makeRules(config), }
  }

  /**
   * Make validation rules for a decimal (6 digits, 2 of which are decimals) property.
   * @param {*} specifications Additional values to overwrite rule defaults.
   * @returns An object containing the property's max character length and rules.
   */
  makeDecimal6_2Rules({ nullable = null } = {}) {
    let config = new ValidationRulesConfig(RULES_TYPES.DECIMAL6_2)
    config = appendRules({ config, nullable, })
    return { length: config.getLength(), rules: makeRules(config), }
  }

  /**
   * Make validation rules for a language property.
   * @returns An object containing the rules.
   */
  makeLanguageRules() {
    let config = new ValidationRulesConfig(RULES_TYPES.LANGUAGE)
    return { rules: makeRules(config), }
  }

  /**
   * Make validation rules for an integer property (a MySQL int).
   * @param {*} specifications Additional values to overwrite rule defaults.
   * @returns An object containing the property's max character length and rules.
   */
  makeIntRules({ length = null, nullable = null } = {}) {
    let config = new ValidationRulesConfig(RULES_TYPES.INT)
    config = appendRules({ config, length, nullable, })
    return { length: config.getLength(), rules: makeRules(config), }
  }

  /**
   * Make validation rules for a medium-integer property (a MySQL mediumint).
   * @param {*} specifications Additional values to overwrite rule defaults.
   * @returns An object containing the property's max character length and rules.
   */
  makeMediumIntRules({ length = null, nullable = null } = {}) {
    let config = new ValidationRulesConfig(RULES_TYPES.MEDIUMINT)
    config = appendRules({ config, length, nullable, })
    return { length: config.getLength(), rules: makeRules(config), }
  }

  /**
   * Make validation rules for a phone number property.
   * @param {*} specifications Additional values to overwrite rule defaults.
   * @returns An object containing the property's max character length and rules.
   */
  makePhoneNumberRules({ nullable = null } = {}) {
    let config = new ValidationRulesConfig(RULES_TYPES.PHONE_NUMBER)
    config = appendRules({ config, nullable, })
    return { length: config.getLength(), rules: makeRules(config), }
  }

  /**
   * Make validation rules for a postal code property.
   * @returns An object containing the property's max character length and rules.
   */
  makePostalCodeRules() {
    let config = new ValidationRulesConfig(RULES_TYPES.POSTAL_CODE)
    return { length: config.getLength(), rules: makeRules(config), }
  }

  /**
   * Make validation rules for a province property.
   * @returns An object containing the rules.
   */
  makeProvinceRules() {
    let config = new ValidationRulesConfig(RULES_TYPES.PROVINCE)
    return { rules: makeRules(config), }
  }

  /**
   * Make validation rules for a Date property.
   * @param {*} specifications Additional values to overwrite rule defaults.
   * @returns An object containing the property's max character length and rules.
   */
  makeStringRules({ length = null, nullable = null, format = null, regexp = null, primaryKey = null } = {}) {
    let config = new ValidationRulesConfig(RULES_TYPES.STRING)
    config = appendRules({ config, length, nullable, format, regexp, primaryKey, })
    return { length: config.getLength(), rules: makeRules(config), }
  }

  /**
   * Make validation rules for a tiny-integer property (a MySQL tinyint).
   * @param {*} specifications Additional values to overwrite rule defaults.
   * @returns An object containing the property's max character length and rules.
   */
  makeTinyIntRules({ length = null, nullable = null } = {}) {
    let config = new ValidationRulesConfig(RULES_TYPES.TINYINT)
    config = appendRules({ config, length, nullable, })
    return { length: config.getLength(), rules: makeRules(config), }
  }

  /**
   * Make validation rules for a URL property.
   * @param {*} specifications Additional values to overwrite rule defaults.
   * @returns An object containing the property's max character length and rules.
   */
   makeUrlRules({ length = null, nullable = null, primaryKey = null } = {}) {
    let config = new ValidationRulesConfig(RULES_TYPES.URL)
    config = appendRules({ config, length, nullable, primaryKey, })
    return { length: config.getLength(), rules: makeRules(config), }
  }

  /**
   * Make validation rules for a 'yes or no' property.
   * @returns An object containing the rules.
   */
  makeYesOrNoRules() {
    let config = new ValidationRulesConfig(RULES_TYPES.YES_OR_NO)
    return { rules: makeRules(config), }
  }
}

const validationRulesFactory = new ValidationRulesFactory()
export default validationRulesFactory