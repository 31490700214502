import reportService from '../../services/api/report.service'

const report = {
  namespaced: true,
  state: {
    reportList: [],
    reportTypeList: [],
    reportParameterList: [],
  },
  getters: {
    getReportList: state => state.reportList,
    getReportTypeList: state => state.reportTypeList,
    getReportParameterList: state => state.reportParameterList,
    hasReportList: state => state.reportList.length > 0,
    hasReportTypeList: state => state.reportTypeList.length > 0,
    hasReportParameterList: state => state.reportParameterList.length > 0,
  },
  mutations: {
    setReportList(state, list) {
      state.reportList = list
    },
    setReportTypeList(state, list) {
      state.reportTypeList = list
    },
    setReportParameterList(state, list) {
      state.reportParameterList = list
    },
  },
  actions: {
    fetchReport({ commit, getters }, { reportId, reportParameters }) {
      return reportService.getReport(reportId, reportParameters)
        .then(resultSet => resultSet)
        .catch(error => { throw error })
    },

    fetchReportsList({ commit, getters }) {
      if (getters.hasReportList) {
        return
      }

      return reportService.getReportsList()
        .then(list => {
          let formattedList = list.map(report => report.download_only === "Y" ? { ...report, download_only: true } : { ...report, download_only: false })
          commit('setReportList', formattedList)
        })
        .catch(error => { throw error })
    },

    fetchReportTypesList({ commit, getters }) {
      if (getters.hasReportTypeList) {
        return
      }

      return reportService.getReportTypeList()
        .then(list => commit('setReportTypeList', list))
        .catch(error => { throw error })
    },

    fetchReportParameterList({ commit, getters }) {
      if (getters.hasReportParameterList) {
        return
      }

      return reportService.getReportParameterList()
        .then(list => commit('setReportParameterList', list))
        .catch(error => { throw error })
    }
  }
}

export default report