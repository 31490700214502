import { cloneDeep } from 'lodash'

// Types of validation rules.
export const RULES_TYPES = {
  DATE: 'DATE',
  DECIMAL: 'DECIMAL',
  DECIMAL6_2: 'DECIMAL6_2',
  INT: 'INT',
  LANGUAGE: 'LANGUAGE',
  MEDIUMINT: 'MEDIUMINT',
  PHONE_NUMBER: 'PHONE_NUMBER',
  POSTAL_CODE: 'POSTAL_CODE',
  PROVINCE: 'PROVINCE',
  STRING: 'STRING',
  TINYINT: 'TINYINT',
  URL: 'URL',
  YES_OR_NO: 'YES_OR_NO',
}

// Abstract configs extended by the rule configs below.
const ABSTRACT_CONFIGS = {
  [RULES_TYPES.DECIMAL]: {
    min: 0,
    ignoreLength: true,
    type: RULES_TYPES.DECIMAL,
  },
  [RULES_TYPES.INT]: {
    min: 0,
    ignoreLength: true,
    type: RULES_TYPES.INT,
  },
  [RULES_TYPES.STRING]: {
    type: RULES_TYPES.STRING,
  },
}

// Concrete configs by type.
const RULE_CONFIGS = {
  [RULES_TYPES.DATE]: {
    length: 10,
    ignoreLength: true,
    format: 'YYYY-MM-DD',
    regexp: new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$'),
    ...ABSTRACT_CONFIGS[RULES_TYPES.STRING],
  },
  [RULES_TYPES.DECIMAL6_2]: {
    length: 7,
    max: 9999.99,
    ...ABSTRACT_CONFIGS[RULES_TYPES.DECIMAL],
  },
  [RULES_TYPES.LANGUAGE]: {
    length: 2,
    format: 'a valid 2-character language code',
    regexp: new RegExp('^(EN|FR)$'),
    ...ABSTRACT_CONFIGS[RULES_TYPES.STRING],
  },
  [RULES_TYPES.INT]: {
    length: 10,
    max: 4294967295,
    ...ABSTRACT_CONFIGS[RULES_TYPES.INT],
  },
  [RULES_TYPES.MEDIUMINT]: {
    length: 8,
    max: 16777215,
    ...ABSTRACT_CONFIGS[RULES_TYPES.INT],
  },
  [RULES_TYPES.PHONE_NUMBER]: {
    length: 20,
    format: '###.###.#### or #.###.###.####',
    regexp: new RegExp('^([0-9]\.)?([0-9]{3}\.){2}[0-9]{4}$'),
    ...ABSTRACT_CONFIGS[RULES_TYPES.STRING],
  },
  [RULES_TYPES.POSTAL_CODE]: {
    length: 7,
    ignoreLength: true,
    format: 'A1A 1A1',
    regexp: new RegExp('^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$'),
    ...ABSTRACT_CONFIGS[RULES_TYPES.STRING],
  },
  [RULES_TYPES.PROVINCE]: {
    length: 2,
    ignoreLength: true,
    format: 'a valid 2-character province code',
    regexp: new RegExp('^(AB|BC|MB|NB|NL|NT|NS|NU|ON|PE|QC|SK|YT)$'),
    ...ABSTRACT_CONFIGS[RULES_TYPES.STRING],
  },
  [RULES_TYPES.STRING]: {
    ...ABSTRACT_CONFIGS[RULES_TYPES.STRING],
  },
  [RULES_TYPES.TINYINT]: {
    length: 3,
    max: 255,
    ...ABSTRACT_CONFIGS[RULES_TYPES.INT],
  },
  [RULES_TYPES.URL]: {
    ...ABSTRACT_CONFIGS[RULES_TYPES.STRING],
    format: 'remove the "www." prefix',
    regexp: new RegExp('^(?![wW]{3}\.).*$'),
  },
  [RULES_TYPES.YES_OR_NO]: {
    length: 1,
    ignoreLength: true,
    format: 'Y or N',
    regexp: new RegExp('^[YN]$'),
    ...ABSTRACT_CONFIGS[RULES_TYPES.STRING],
  },
}

/**
 * Configuration class for validation rules.
 */
export default class ValidationRulesConfig {
  /**
   * Create validation rules for a property by type.
   * @param {string} type Data-type of property.
   */
  constructor (type) {
    this.type = type
    this.config = cloneDeep(RULE_CONFIGS[this.type])
    this.config.required = true
  }

  getConfig() {
    return this.config
  }

  getFormat() {
    return this.config.format
  }

  getFormatRegexp() {
    return this.config.regexp
  }

  getLength() {
    return this.config.length
  }

  getMax() {
    return this.config.max
  }

  getMin() {
    return this.config.min
  }

  hasExactlyConstraint() {
    return 'exactly' in this.config
  }

  hasFormatConstraint() {
    return 'format' in this.config
  }

  hasLengthConstraint() {
    return 'length' in this.config && !this.config.ignoreLength
  }

  hasNumericRangeConstraint() {
    return 'min' in this.config && 'max' in this.config
  }

  hasRequiredConstraint() {
    return 'required' in this.config && this.config.required === true
  }

  isDecimalType() {
    return this.config.type === RULES_TYPES.DECIMAL
  }

  isIntType() {
    return this.config.type === RULES_TYPES.INT
  }

  isNumericType() {
    return this.isIntType() || this.isDecimalType()
  }

  isStringType() {
    return this.config.type === RULES_TYPES.STRING
  }

  format(format) {
    this.config.format = format
    return this
  }

  ignoreLength() {
    this.config.ignoreLength = true
    return this
  }

  length(length) {
    this.config.length = length
    return this
  }

  nullable() {
    this.config.required = false
    return this
  }

  primaryKey() {
    this.config.primaryKey = true
    return this
  }

  regexp(regexp) {
    this.config.regexp = regexp
    return this
  }
}