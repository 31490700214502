import { isNil } from 'lodash'

import { TYPE } from '../../models/dto/Agent'
import Repository from './repository'

export const URL = {
  [TYPE.AGENT]: 'agent',
  [TYPE.AGENT_ALIAS]: 'alias',
  [TYPE.AGENT_BUSINESS_ADDRESS]: 'business-address',
  [TYPE.AGENT_CLIENT_ASSN]: 'client-assn',
  [TYPE.AGENT_MORTGAGE_DEAL_ASSN]: 'mortgage-deal-assn',
  [TYPE.AGENT_PROFILE]: 'profile',
  [TYPE.AGENT_SOCIAL_MEDIA]: 'social-media',
  [TYPE.AGENT_TEAM_ASSN]: 'team-assn',
}

class AgentService {
  basicAgentProfiles() {
    return Repository.get(`agent-profile/basic-info/`)
  }

  create(endpoint, data) {
    return Repository.post(this.getURL(endpoint), data)
  }

  createFull(data) {
    return Repository.post(`${URL[TYPE.AGENT]}/create-full/`, data)
  }

  delete(endpoint) {
    return Repository.delete(this.getURL(endpoint))
  }

  detail(endpoint) {
    return Repository.get(this.getURL(endpoint))
  }

  list(endpoint) {
    return Repository.get(this.getURL(endpoint))
  }

  update(endpoint, data) {
    return Repository.put(this.getURL(endpoint), data)
  }

  getURL({ type, agent_id, id, assn_id, }) {
    if (isNil(type)) {
      throw new ReferenceError('Data-type is nil.')
    }

    let url = `${URL[TYPE.AGENT]}/`

    if (agent_id) {
      url += `${agent_id}/`
    }

    if (type !== TYPE.AGENT) {
      if (type === TYPE.AGENT_CLIENT_ASSN || type === TYPE.AGENT_MORTGAGE_DEAL_ASSN) {
        url += `${URL[TYPE.AGENT_PROFILE]}/`
      }
      else if (type === TYPE.AGENT_ALIAS) {
        url += `${URL[TYPE.AGENT_ALIAS]}/`
      }
      else {
        url += `${URL[type]}/`
      }
    }

    if (id) {
      url += `${id}/`
      if (type === TYPE.AGENT_CLIENT_ASSN || type === TYPE.AGENT_MORTGAGE_DEAL_ASSN) {
        url += `${URL[type]}/`
      }
    }

    if (assn_id) {
      url += `${assn_id}/`
    }

    return url
  }
}

const agentService = new AgentService()
export default agentService