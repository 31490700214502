import { cloneDeep } from "lodash"
import CalendarEventService from "../../services/api/calendar.event.service"

const calendarEvent = {
  namespaced: true,
  state: {
    calendarEventListCache: [], // List of all cached events.
    upcomingEventsCache: [],    // List of all cached upcoming events.
  },
  getters: {
    getCachedCalendarEventList: state => () => state.calendarEventListCache,
    hasCachedCalendarEventList: state => () => state.calendarEventListCache.length > 0,
    getCachedUpcomingEvents: state => () => state.upcomingEventsCache,
    hasCachedUpcomingEvents: state => () => state.upcomingEventsCache.length > 0,
  },
  mutations: {
    cacheCalendarEventList (state, { list }) {
      state.calendarEventListCache = cloneDeep(list)
    },
    cacheUpcomingEvents (state, { list }) {
      state.upcomingEventsCache = cloneDeep(list)
    },
  },
  actions: {
    createData ({ commit }, payload) {
      return CalendarEventService.create(payload)
        .then(response => response)
        .catch(error => { throw error })
    },

    deleteData ({ commit }, event_id) {
      return CalendarEventService.delete(event_id)
        .then(response => response)
        .catch(error => { throw error })
    },

    fetchDetail ({ commit }, event_id) {
      return CalendarEventService.detail(event_id)
        .then(response => response)
        .catch(error => { throw error })
    },

    fetchList ({ commit }) {
      return CalendarEventService.list()
        .then(response => response)
        .catch(error => { throw error })
    },

    updateData ({ commit }, payload) {
      return CalendarEventService.update(payload)
        .then(response => response)
        .catch(error => { throw error })
    },

    fetchCalendarEvents ({ dispatch, commit, getters }, { force = false }) {
      // If the calendar event list is cached or we're not forcing the fetch, return the cached calendar event list.
      if (getters.hasCachedCalendarEventList() && !force) {
        return getters.getCachedCalendarEventList()
      }

      // Otherwise, fetch it, cache the new calendar event list, and return it to the caller.
      return dispatch('fetchList')
        .then(list => {
          commit('cacheCalendarEventList', { list, })
          return list
        })
        .catch(error => {
          throw 'Error fetching calendar event list: ' + error
        })
    },

    fetchUpcomingEvents ({ commit, getters }) {
      // If the calendar event list is cached or we're not forcing the fetch, return the cached calendar event list.
      if (getters.hasCachedUpcomingEvents()) {
        return getters.getCachedUpcomingEvents()
      }

      // Otherwise, fetch it, cache the new calendar event list, and return it to the caller.
      return CalendarEventService.listUpcomingEvents()
        .then(list => {
          commit('cacheUpcomingEvents', { list, })
          return list
        })
        .catch(error => {
          throw 'Error fetching upcoming events list: ' + error
        })
    },
  },
}

export default calendarEvent