import { getField, updateField } from 'vuex-map-fields'

import { DEFAULT_USER } from '../../constants'
import User from '../../models/User'
import UserService from '../../services/api/user.service'

const auth = {
  namespaced: true,
  state: {
    user: new User(DEFAULT_USER),
  },
  getters: {
    getField,
    getUserId: state => state.user.id,
    getUserEmail: state => state.user.email,
    getUserName: state => state.user.name,
    getUser: state => state.user,
  },
  mutations: {
    updateField,

    setUser (state, user) {
      state.user = user
    },
  },
  actions: {
    activateUser({ commit }, { uid, token }) {
      return UserService.activateUser({ uid, token, })
        .then(response => response)
        .catch(error => {
          throw error
        })
    },
    confirmPasswordReset ({ commit }, { uid, token, new_password, re_new_password, }) {
      return UserService.confirmPasswordReset({ uid, token, new_password, re_new_password, })
        .then(response => response)
        .catch(error => {
          throw error
        })
    },
    createUser({ commit }, { payload }) {
      return UserService.createUser(payload)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },
    fetchUser({ commit }) {
      return UserService.fetchUser()
        .then(response => {
          commit('setUser', new User(response))
          return response
        })
        .catch(error => {
          throw error
        })
    },
    fetchUserProfile({ commit }, { id = '' }) {
      return UserService.fetchUserProfile(id)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },
    resendActivation ({ commit }, { email }) {
      return UserService.resendActivation({ email, })
        .then(response => response)
        .catch(error => {
          throw error
        })
    },
    resetPassword ({ commit }, { email }) {
      return UserService.resetPassword({ email, })
        .then(response => response)
        .catch(error => {
          throw error
        })
    },
    resetUser({ commit }) {
      commit('setUser', new User(DEFAULT_USER))
    },
    updateUser({ commit }, { id, payload }) {
      return UserService.updateUser(id, payload)
        .then(response => response)
        .catch(error => {
          throw error
        })
    },
  },
}

export default auth