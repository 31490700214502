import { isNil } from 'lodash'
import jwt from 'jsonwebtoken'

import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../constants'
import Repository from './repository'

// URLs associated with this service.
const JWT_URL = 'jwt'
const BLACKLIST_TOKEN_URL = `${JWT_URL}/blacklist/`
const LOGIN_URL = `${JWT_URL}/create/`
const REFRESH_TOKEN_URL = `${JWT_URL}/refresh/`
const VERIFY_TOKEN_URL = `${JWT_URL}/verify/`

class AuthService {
  blackListToken(token) {
    return Repository.post(BLACKLIST_TOKEN_URL, token)
  }

  /**
   * Determine if the sessions is authenticated.
   * @returns TRUE when the access token is valid. FALSE when the access token has expired and the
   * attempt to refresh it failed.
   */
  async isAuthenticated() {
    let authenticated = false
    const accessToken = localStorage.getItem(ACCESS_TOKEN)

    if (!isNil(accessToken)) {
      const accessDecoded = jwt.decode(accessToken, { complete: true });
      const now = Math.ceil(Date.now() / 1000)
      if (now >= accessDecoded.payload.exp) {
        // Access token has expired, attempt to refresh it.
        let refreshToken = localStorage.getItem(REFRESH_TOKEN)
        if (!isNil(refreshToken)) {
          const refreshDecoded = jwt.decode(refreshToken, { complete: true })

          // Refresh token hasn't expired, attempt to use it to refresh the access token.
          if (now < refreshDecoded.payload.exp) {
            await this.refreshAccessToken(refreshToken)
              .then(response => {
                // Access token has been successfully refreshed. User is considered authenticated.
                this.setToken(ACCESS_TOKEN, response.access)
                authenticated = true
              })
          }
        }
      } else {
        authenticated = true
      }
    }

    return authenticated
  }

  login(user) {
    return Repository.post(LOGIN_URL, user)
      .then(response => {
        this.setToken(ACCESS_TOKEN, response.access)
        this.setToken(REFRESH_TOKEN, response.refresh)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  logout() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN)

    if (refreshToken) {
      this.blackListToken({ refresh_token: refreshToken, })
        .catch(error => {
          // console.log('black-listing error', error)
        })
    }
  
    this.removeTokens()
  }

  refreshAccessToken(refresh) {
    return Repository.post(REFRESH_TOKEN_URL, { refresh, })
  }

  removeTokens() {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
  }

  setToken(name, token) {
    localStorage.setItem(name, token)
  }

  verify(token) {
    return Repository.post(VERIFY_TOKEN_URL, { token, })
  }
}

const authService = new AuthService()
export default authService