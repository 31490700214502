import { getField, updateField } from 'vuex-map-fields'
import Message, { ErrorMessage, SuccessMessage } from '../../models/Message'

const message = {
    namespaced: true,
    state: {
        messages: [],
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,

        enqueue (state, message) {
            state.messages.push(message)
        },
    },
    actions: {
        enqueueMessage ({ commit }, messageConfig) {
            commit('enqueue', new Message(messageConfig).getMessage())
        },
        errorMessage ({ commit }, message) {
            commit('enqueue', new ErrorMessage(message))
        },
        successMessage ({ commit }, message) {
            commit('enqueue', new SuccessMessage(message))
        },
    },
}

export default message