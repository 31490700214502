import { isNil } from 'lodash'

import { ACCESS_TOKEN, ROUTES, } from '../../constants'
import Repository from './repository'
import { push } from '../../router'
import AuthService from './auth.service'

export default function initAxiosInterceptors() {
  Repository.api.interceptors.request.use(
    config => {
      const token = localStorage.getItem(ACCESS_TOKEN)
      if (!isNil(token)) {
        config.headers.common['Authorization'] = `JWT ${token}`
      }
      return Promise.resolve(config)
    },
    error => {
      return Promise.reject(error)
    }
  )

  Repository.api.interceptors.response.use(
    response => {
      switch (response.status) {
        case 200: case 201: case 204: case 205:
          return Promise.resolve(response)
        default:
          return Promise.reject(response)
      }
    },
    error => {
      if (error.response && error.response.status && error.response.data) {
        let errorBody = error.response.data

        switch (error.response.status) {
          case 400:
            // Bad Request
            // console.log('interceptor: bad request')
            break
  
          case 401:
            // Unauthorized
            
            // Attempt to refresh the session.
            if (error.response.data.code === 'token_not_valid') {
              AuthService.isAuthenticated()
                then(authenticated => {
                  if (authenticated) {
                    const originalRequest = error.config
                    originalRequest.headers['Authorization'] = `JWT ${localStorage.getItem(ACCESS_TOKEN)}`
                    return Repository.api(originalRequest)
                  } else {
                    AuthService.removeTokens()
                    push(ROUTES.SIGN_IN)
                  }
                })
            } 
            break

          case 403:
            // Forbidden
            // console.log('interceptor: forbidden, redirecting to login')
  
            // push(403_ROUTE)
            break
  
          case 404:
            // Not Found
            // console.log('interceptor: not found')
  
            // Overwrite Django's 404 error.
            if (error.response.statusText === 'Not Found') {
              errorBody = { status_code: 404, message: 'Not found' }
            }
            break
  
          case 502:
            // Bad Gateway
            // console.log('interceptor: bad gateway')
            break
        }

        return Promise.reject(errorBody);
      }
  
      alert('Request timed-out due to a server or network error.\nIf error persists, contact the site administrator.')
      return Promise.reject(error.response);
    }
  )
}