import { DIALOGS, ICONS, VUES, TEXT_COLOR, } from "../constants"
import { TYPE as AGENT_TYPE } from "../models/dto/Agent"
import { TYPE as CLIENT_TYPE } from "../models/dto/Client"
import { TYPE as MORTGAGE_DEAL_TYPE } from "../models/dto/MortgageDeal"

// HTML elements.
const SPAN = 'span'

// =================
// Private Functions
// =================

/**
 * Format a data-type to be represented in a help string.
 * @param {string} type Type to format. 
 * @returns A formatted string for the provided data-type.
 */
const formatDataType = (type) => {
    switch (type) {
        case AGENT_TYPE.AGENT: return 'an agent'
        case AGENT_TYPE.AGENT_ALIAS: return 'an alias'
        case AGENT_TYPE.AGENT_BUSINESS_ADDRESS: return 'a business address'
        case AGENT_TYPE.AGENT_CLIENT_ASSN: return 'a client association'
        case AGENT_TYPE.AGENT_MORTGAGE_DEAL_ASSN: return 'a mortgage association'
        case AGENT_TYPE.AGENT_PROFILE: return 'a profile'
        case AGENT_TYPE.AGENT_SOCIAL_MEDIA: return 'a social media'
        case AGENT_TYPE.AGENT_TEAM_ASSN: return 'a team association'
        case CLIENT_TYPE.CLIENT: return 'a client'
        case CLIENT_TYPE.CLIENT_ADDRESS: return 'an address'
        case CLIENT_TYPE.CLIENT_AGENT_ASSN: return 'an agent association'
        case CLIENT_TYPE.CLIENT_CONTACT: return 'contact information'
        case CLIENT_TYPE.CLIENT_MORTGAGE_DEAL_ASSN: return 'a mortgage deal association'
        case CLIENT_TYPE.CLIENT_PROGRAM_CONTROL: return 'a program control'
        case MORTGAGE_DEAL_TYPE.MORTGAGE: return 'a mortgage'
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL: return 'a mortgage deal'
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_AGENT_ASSN: return 'an agent association'
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_CLIENT_ASSN: return 'a client association'
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_COMMENT: return 'a comment'
        case MORTGAGE_DEAL_TYPE.MORTGAGE_PROPERTY: return 'a property'
        case MORTGAGE_DEAL_TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL: return 'a program control'
        default:
          console.log(`Error: a formatDataType() for ${type} does not exist`)
    }
}

/**
 * Wrap content in an HTML class property.
 * @param {Array<String>} content content within the class prop
 * @returns An HTML class property.
 */
const getClassProp = content => `class="${content.join(' ')}"`

/**
  * Convert some data into a valid HTML tag.
  * @param {string} tag tag to generate HTML for.
  * @param {Array<String>} props props for the tag
  * @param {string} content content within the tag
  * @returns HTML tag.
  */
const getHTML = (tag, props, content = '') => `<${tag} ${Object.values(props).join(' ')}>${content}</${tag}>`
 
/**
  * Generate mdi-icon HTML for use in Vuetify.
  * @param {string} icon mdi icon 
  * @param {string} color text color for icon
  * @returns An icon in as a span-tag.
  */
const getIconHTML = (icon, color) => getHTML('span', [getClassProp([color, 'mdi', icon,]),])

// CSS classes for the different kinds of text colors.
const errorTextClass = getClassProp([TEXT_COLOR.ERROR,])
const primaryTextClass = getClassProp([TEXT_COLOR.PRIMARY,])
const secondaryTextClass = getClassProp([TEXT_COLOR.SECONDARY,])
const successTextClass = getClassProp([TEXT_COLOR.SUCCESS,])

class HelpService {

    getAddButtonHelp (type) {
        return `Click the ${getHTML(SPAN, [successTextClass,], 'Add')} button (${getIconHTML(TEXT_COLOR.SUCCESS, ICONS.ADD_GENERIC)}) to add ${formatDataType(type)}.`
    }

    getCancelButtonHelp () {
        return `Click the ${getHTML(SPAN, [secondaryTextClass,], 'Cancel')} button (${getIconHTML(TEXT_COLOR.SECONDARY, ICONS.CANCEL)}) to cancel editing.`
    }

    getDeleteButtonHelp (type) {
        return `Click the ${getHTML(SPAN, [errorTextClass,], 'Delete')} button (${getIconHTML(TEXT_COLOR.ERROR, ICONS.DELETE)}) to delete ${formatDataType(type)}.`
    }

    getEditButtonHelp (type) {
        return `Click the ${getHTML(SPAN, [primaryTextClass,], 'Edit')} button (${getIconHTML(TEXT_COLOR.PRIMARY, ICONS.EDIT)}) to modify ${formatDataType(type)}.`
    }

    getSaveButtonHelp () {
        return `Click the ${getHTML(SPAN, [successTextClass,], 'Save')} button (${getIconHTML(TEXT_COLOR.SUCCESS, ICONS.SAVE)}) to save any changes.`
    }

    getPageHelp (page) {
      switch (page) {
        case VUES.AGENT: return [
          `Each panel contains specific information associated with this agent.`,
          `Panel titles display the ${getHTML(SPAN, [secondaryTextClass,], 'category')} and a ${getHTML(SPAN, [primaryTextClass,], 'summary')} of its content.`,
          `Expand and collapse each panel by clicking on its title bar.`,
        ]
        case VUES.AGENTS: return [
          'Select an agent to view their profile.',
          `Click the ${getHTML(SPAN, [successTextClass,], 'Add')} button (${getIconHTML(TEXT_COLOR.SUCCESS, ICONS.ADD_GENERIC)}) to create a new agent.`,
          `Click the ${getHTML(SPAN, [primaryTextClass,], 'Refresh')} button (${getIconHTML(TEXT_COLOR.PRIMARY, ICONS.REFRESH)}) to refresh the data displayed in the table.`,
        ]

        case VUES.CLIENT: return [
          `Each panel contains specific information associated with this client.`,
          `Panel titles display the ${getHTML(SPAN, [secondaryTextClass,], 'category')} and a ${getHTML(SPAN, [primaryTextClass,], 'summary')} of its content.`,
          `Expand and collapse each panel by clicking on its title bar.`,
          `Click the ${getHTML(SPAN, [primaryTextClass,], 'Refresh')} button (${getIconHTML(TEXT_COLOR.PRIMARY, ICONS.REFRESH)}) to load a new copy of the client.`,
        ]
        case VUES.CLIENTS: return [
          'Select a client to view their profile.',
          `Click the ${getHTML(SPAN, [successTextClass,], 'Add')} button (${getIconHTML(TEXT_COLOR.SUCCESS, ICONS.ADD_GENERIC)}) to create a new client.`,
          `Click the ${getHTML(SPAN, [primaryTextClass,], 'Refresh')} button (${getIconHTML(TEXT_COLOR.PRIMARY, ICONS.REFRESH)}) to refresh the data displayed in the table.`,
        ]
  
        case VUES.MORTGAGE_DEAL: return [
          `Each panel contains specific information associated with this mortgage deal.`,
          `Panel titles display the ${getHTML(SPAN, [secondaryTextClass,], 'category')} and a ${getHTML(SPAN, [primaryTextClass,], 'summary')} of its content.`,
          `Expand and collapse each panel by clicking on its title bar.`,
          `Click the ${getHTML(SPAN, [primaryTextClass,], 'Refresh')} button (${getIconHTML(TEXT_COLOR.PRIMARY, ICONS.REFRESH)}) to load a new copy of the mortgage deal.`,
        ]
        case VUES.MORTGAGE_DEALS: return [
          'Select a mortgage deal to view its profile.',
          `Click the ${getHTML(SPAN, [successTextClass,], 'Add')} button (${getIconHTML(TEXT_COLOR.SUCCESS, ICONS.ADD_GENERIC)}) to create a new mortgage deal.`,
          `Click the ${getHTML(SPAN, [primaryTextClass,], 'Refresh')} button (${getIconHTML(TEXT_COLOR.PRIMARY, ICONS.REFRESH)}) to refresh the data displayed in the table.`,
        ]

        case VUES.VALID_TABLES: return [
          `Select a valid table from the dropdown to view its contents.`,
          `Click the ${getHTML(SPAN, [successTextClass,], 'Add')} button (${getIconHTML(TEXT_COLOR.SUCCESS, ICONS.ADD_GENERIC)}) to create a new entry in the valid table.`,
          `Click the ${getHTML(SPAN, [primaryTextClass,], 'Refresh')} button (${getIconHTML(TEXT_COLOR.PRIMARY, ICONS.REFRESH)}) to refresh the data displayed in the table.`,
          `Click on a table row to edit the entry.`,
          `Click the ${getHTML(SPAN, [errorTextClass,], 'Delete')} button (${getIconHTML(TEXT_COLOR.ERROR, ICONS.DELETE)}) to remove the entry from the valid table.`,
        ]
        case DIALOGS.VALID_TABLES: return [
          `Click the ${getHTML(SPAN, [successTextClass,], 'Save')} button (${getIconHTML(TEXT_COLOR.SUCCESS, ICONS.SAVE)}) to create or update an entry.`,
          `Click the ${getHTML(SPAN, [errorTextClass,], 'Delete')} button (${getIconHTML(TEXT_COLOR.ERROR, ICONS.DELETE)}) to delete the entry.`,
          `Click the ${getHTML(SPAN, [secondaryTextClass,], 'Close')} button (${getIconHTML(TEXT_COLOR.SECONDARY, ICONS.CLOSE)}) to close this prompt.`,
        ]

        case VUES.LOAD_UPDATE_DATA: return [
          `Columns suffixed with ${getHTML(SPAN, [errorTextClass,], '"**"')} are ${getHTML(SPAN, [errorTextClass,], 'required')}.`,
          `Columns suffixed with ${getHTML(SPAN, [errorTextClass,], '"^^"')} must all have a value or remain empty, as a group. Meaning, if even one ${getHTML(SPAN, [errorTextClass,], '"^^"')}-column in a row contains data, all ${getHTML(SPAN, [errorTextClass,], '"^^"')}-columns in that row must contain data.`,
          `Required columns must contain a value.`,
          `${getHTML(SPAN, [errorTextClass,], 'Warning')} - Optional columns that are empty will overwrite that column's value in the database (deleting the value, in effect).`,
        ]
        case VUES.LOAD_NEW_DATA: return [
          `Columns suffixed with ${getHTML(SPAN, [errorTextClass,], '"**"')} are ${getHTML(SPAN, [errorTextClass,], 'required')}.`,
          `Columns suffixed with ${getHTML(SPAN, [errorTextClass,], '"^^"')} must all have a value or remain empty, as a group. Meaning, if even one ${getHTML(SPAN, [errorTextClass,], '"^^"')}-column in a row contains data, all ${getHTML(SPAN, [errorTextClass,], '"^^"')}-columns in that row must contain data.`,
          `Required columns must contain a value.`,
        ]

        case VUES.REPORTS: return [
            `Select one of the reports in the list below to have its contents generated.`,
            `Depending on the type of report, additional steps may be required in order to generate it.`,
          ]
      }
    }
}

export default new HelpService()