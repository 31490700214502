import Repository from './repository'

const USERS = 'users'
const USER_PROFILE = 'user-profile'
const ACTIVATE_USER_URL = `${USERS}/activation/`
const CONFIRM_PASSWORD_RESET_URL =`${USERS}/reset_password_confirm/`
const CREATE_USER_URL = `${USERS}/`
const FETCH_USER_URL = `${USERS}/me/`
const FETCH_USER_PROFILE_URL = `${USER_PROFILE}`
const RESEND_ACTIVATION_URL = `${USERS}/resend_activation/`
const RESET_PASSWORD_URL = `${USERS}/reset_password/`

class UserService {
  activateUser (data) {
    return Repository.post(ACTIVATE_USER_URL, data)
  }
  createUser (data) {
    return Repository.post(CREATE_USER_URL, data)
  }
  fetchUser () {
    return Repository.get(FETCH_USER_URL)
  }
  fetchUserProfile (id = '') {
    return Repository.get(`${FETCH_USER_PROFILE_URL}/${id}`)
  }
  confirmPasswordReset (data) {
    return Repository.post(CONFIRM_PASSWORD_RESET_URL, data)
  }
  resendActivation (data) {
    return Repository.post(RESEND_ACTIVATION_URL, data)
  }
  resetPassword (data) {
    return Repository.post(RESET_PASSWORD_URL, data)
  }
  updateUser (id, data) {
    return Repository.put(`${USER_PROFILE}/${id}/`, data)
  }
}

const userService = new UserService()
export default userService