import { isNil } from 'lodash'

import { TYPE } from '../../models/dto/MortgageDeal'
import Repository from './repository'

export const URL = {
  [TYPE.MORTGAGE_DEAL]: 'mortgage-deal',
  [TYPE.MORTGAGE_DEAL_COMMENT]: 'comment',
  [TYPE.MORTGAGE_DEAL_AGENT_ASSN]: 'agent-assn',
  [TYPE.MORTGAGE_DEAL_CLIENT_ASSN]: 'client-assn',
  [TYPE.MORTGAGE]: 'mortgage',
  [TYPE.MORTGAGE_PROPERTY]: 'property',
  [TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: 'program-control',
  'BASIC_INFO': 'basic-info',
}

class MortgageDealService {
  basicList() {
    return Repository.get(`${URL[TYPE.MORTGAGE_DEAL]}/${URL.BASIC_INFO}/`)
  }

  create(endpoint, data) {
    return Repository.post(this.getURL(endpoint), data)
  }

  createFull(data) {
    return Repository.post(`${URL[TYPE.MORTGAGE_DEAL]}/create-full/`, data)
  }

  delete(endpoint) {
    return Repository.delete(this.getURL(endpoint))
  }

  detail(endpoint) {
    return Repository.get(this.getURL(endpoint))
  }

  list(endpoint) {
    return Repository.get(this.getURL(endpoint))
  }

  update(endpoint, data) {
    return Repository.put(this.getURL(endpoint), data)
  }

  getURL({ type, mortgage_deal_id, mortgage_id, id }) {
    if (isNil(type)) {
      throw new ReferenceError('Data-type is nil.')
    }

    // Set the url for the primary type.
    let url = null
    if (type === TYPE.MORTGAGE || type === TYPE.MORTGAGE_PROPERTY) {
      url = (mortgage_id) ? `${URL[TYPE.MORTGAGE]}/${mortgage_id}/` : `${URL[TYPE.MORTGAGE]}/`
    }
    else {
      url = (mortgage_deal_id) ? `${URL[TYPE.MORTGAGE_DEAL]}/${mortgage_deal_id}/` : `${URL[TYPE.MORTGAGE_DEAL]}/`
    }

    if (type !== TYPE.MORTGAGE_DEAL && type !== TYPE.MORTGAGE) {
      url += (id) ? `${URL[type]}/${id}/` : `${URL[type]}/`
    }

    return url
  }
}

const mortgageDealService = new MortgageDealService()
export default mortgageDealService