export default class User {
  /**
   * Create a user object.
   * @param {string} name Name of the user.
   * @param {string} email Email address of the user.
   * @param {string} id ID of the user.
   */
  constructor({ name, email, id }) {
    this.name = name
    this.email = email
    this.id = id
  }
}