import { isNil } from 'lodash'

import { TYPE } from '../../models/dto/Client'
import Repository from './repository'

const URL = {
  [TYPE.CLIENT]: 'client',
  [TYPE.CLIENT_ADDRESS]: 'address',
  [TYPE.CLIENT_CONTACT]: 'contact',
  [TYPE.CLIENT_AGENT_ASSN]: 'agent-assn',
  [TYPE.CLIENT_MORTGAGE_DEAL_ASSN]: 'mortgage-deal-assn',
  [TYPE.CLIENT_PROGRAM_CONTROL]: 'program-control',
}

class ClientService {  
  create(endpoint, data) {
    return Repository.post(this.getURL(endpoint), data)
  }

  createFull(data) {
    return Repository.post(`${URL[TYPE.CLIENT]}/create-full/`, data)
  }

  delete(endpoint) {
    return Repository.delete(this.getURL(endpoint))
  }

  detail(endpoint) {
    return Repository.get(this.getURL(endpoint))
  }

  list(endpoint) {
    return Repository.get(this.getURL(endpoint))
  }

  update(endpoint, data) {
    return Repository.put(this.getURL(endpoint), data)
  }

  withLoanCode() {
    return Repository.get(`${URL[TYPE.CLIENT]}/with-loan-code/`)
  }

  getURL({ type, client_id, id }) {
    if (isNil(type)) {
      throw new ReferenceError('Data-type is nil.')
    }

    // Set the url for the primary type. 
    let url = (client_id) ? `${URL[TYPE.CLIENT]}/${client_id}/` : `${URL[TYPE.CLIENT]}/`

    if (type !== TYPE.CLIENT) {
      url += (id) ? `${URL[type]}/${id}/` : `${URL[type]}/`
    }

    return url
  }

}

const clientService = new ClientService()
export default clientService