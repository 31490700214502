import { cloneDeep, forEach, isNil } from 'lodash'

import { VALID_TABLES } from '../../constants'
import Agent, { setSelectValidData, TYPE } from '../../models/dto/Agent'
import AgentService from '../../services/api/agent.service'

const agent = {
  namespaced: true,
  state: {
    agentCache: {},
    agentListCache: {},
    basicAgentProfiles: [],
  },
  getters: {
    getBasicAgentProfiles: state => state.basicAgentProfiles,
    getCachedAgent: state => agent_id => cloneDeep(state.agentCache[agent_id]),
    getCachedAgentList: state => () => state.agentListCache,
    
    hasBasicAgentProfiles: state => state.basicAgentProfiles.length > 0,
    hasCachedAgentList: state => () => state.agentListCache.length > 0,
    
    isAgentCached: state => agent_id => !isNil(state.agentCache[agent_id]),
  },
  mutations: {
    setBasicAgentProfiles (state, list) {
      state.basicAgentProfiles = list
    },
    cacheAgent (state, { agent, agent_id }) {
      state.agentCache[agent_id] = cloneDeep(agent)
    },
    cacheAgentList (state, { list }) {
      state.agentListCache = cloneDeep(list)
    },
    clearCache (state) {
      state.agentCache = {}
    },
    removeFromCache (state, { agent_id }) {
      delete state.agentCache[agent_id]
    },
  },
  actions: {
    createData ({ commit, dispatch }, { type, payload, }) {
      const endpointConfig = {
        type,
        agent_id: payload.agent_id,
        id: payload.id ? payload.id : null,
      }

      return AgentService.create(endpointConfig, payload)
        .then(async response => {
          if (type === TYPE.AGENT_PROFILE) {
            try {
              const results = await Promise.all([
                dispatch('fetchList', { type: TYPE.AGENT_CLIENT_ASSN, agent_id: response.agent_id, id: response.id, }),
                dispatch('fetchList', { type: TYPE.AGENT_MORTGAGE_DEAL_ASSN, agent_id: response.agent_id, id: response.id, }),
              ])

              response[TYPE.AGENT_CLIENT_ASSN] = results[0]
              response[TYPE.AGENT_MORTGAGE_DEAL_ASSN] = results[1]
            }
            catch (error) {
              console.error(`Error during association fetches for agent ${response.agent_id}, profile ${response.id}: ${error}`)
              throw error
            }
          }
          return response
        })
        .catch(error => {
          throw error
        })
    },

    createFullAgent ({ commit }, { payload }) {
      return AgentService.createFull(payload)
        .then(response => response)
        .catch(error => { throw error })
    },

    deleteData ({ commit }, { type, agent_id, id, assn_id }) {
      const endpointConfig = { type, agent_id, id, assn_id, }
   
      return AgentService.delete(endpointConfig)
        .then(response => response)
        .catch(error => { throw error })
    },

    fetchBasicAgentProfiles ({ commit, getters }, force = false) {
      if (getters.hasBasicAgentProfiles && !force) {
        return Promise.resolve()
      }

      return AgentService.basicAgentProfiles()
        .then(list => {
          let normalizedList = []

          forEach(list, profile => {
            normalizedList.push({
              'agent_id': profile.agent_id,
              'agent_name': `${profile.agent_id__first_name} ${profile.agent_id__surname}`,
              'profile_id': profile.id,
              'agent_profile_id': profile.profile_id,
            })
          })

          commit('setBasicAgentProfiles', normalizedList)
          return normalizedList
        })
        .catch(error => { throw error })
    },

    fetchDetail ({ commit }, { type, agent_id, id, assn_id }) {
      const endpointConfig = { type, agent_id, id, assn_id, }

      return AgentService.detail(endpointConfig)
        .then(response => response)
        .catch(error => { throw error })
    },

    fetchList ({ commit }, { type, agent_id, id,  }) {
      const endpointConfig = { type, agent_id, id, }

      return AgentService.list(endpointConfig)
        .then(response => response)
        .catch(error => { throw error })
    },

    updateData ({ commit, dispatch }, { type, payload }) {
      const endpointConfig = {
        type,
        agent_id: payload.agent_id,
        id: payload.id ? payload.id : null,
      }

      return AgentService.update(endpointConfig, payload)
        .then(async response => response)
        .catch(error => { throw error })
    },

    async fetchAgent ({ dispatch, commit, getters }, { agent_id, force = false }) {
      // If the Agent is cached or we're not forcing the fetch, return the cached Agent.
      if (getters.isAgentCached(agent_id) && !force) {
        return getters.getCachedAgent(agent_id)
      }
      // Otherwise, fetch it, cache the new Agent, and return it to the caller.

      // Fetch valid table dependencies and pass them to Agent.
      try {
        const agentProfiles = await dispatch('valid/fetchList', { type: VALID_TABLES.AGENT_PROFILE, }, { root: true, })
        const agentTeams = await dispatch('valid/fetchList', { type: VALID_TABLES.AGENT_TEAM, }, { root: true, })
        const provinces = await dispatch('valid/fetchList', { type: VALID_TABLES.PROVINCE, }, { root: true, })
        const socialMedia = await dispatch('valid/fetchList', { type: VALID_TABLES.SOCIAL_MEDIA, }, { root: true, })
        setSelectValidData({ provinces, agentTeams, agentProfiles, socialMedia, })
      }
      catch(error) {
        throw 'Error fetching valid table data: ' + error
      }

      let data = {};
      return dispatch('fetchDetail', { type: TYPE.AGENT, agent_id, })
        .then(primaryData => {
          // Store primary Agent data.
          data[TYPE.AGENT] = primaryData

          return primaryData
        })
        .then(async (primaryData) => {
          // Store secondary Agent data.
          try {
            let secondaryData = await Promise.all([
              dispatch('fetchList', { type: TYPE.AGENT_BUSINESS_ADDRESS, agent_id, }),
              dispatch('fetchList', { type: TYPE.AGENT_PROFILE, agent_id, }),
              dispatch('fetchList', { type: TYPE.AGENT_SOCIAL_MEDIA, agent_id, }),
              dispatch('fetchList', { type: TYPE.AGENT_TEAM_ASSN, agent_id, }),
              dispatch('fetchList', { type: TYPE.AGENT_ALIAS, agent_id, }),
            ])

            data[TYPE.AGENT_BUSINESS_ADDRESS] = secondaryData[0];
            data[TYPE.AGENT_PROFILE] = secondaryData[1];
            data[TYPE.AGENT_SOCIAL_MEDIA] = secondaryData[2];
            data[TYPE.AGENT_TEAM_ASSN] = secondaryData[3];
            data[TYPE.AGENT_ALIAS] = secondaryData[4];

            let associationPromises = []
            forEach(data[TYPE.AGENT_PROFILE], (profile, i) => {
              associationPromises.push(
                dispatch('fetchList', { type: TYPE.AGENT_CLIENT_ASSN, agent_id, id: profile.id, }),
                dispatch('fetchList', { type: TYPE.AGENT_MORTGAGE_DEAL_ASSN, agent_id, id: profile.id, }),
              )
            })

            try {
              const results = await Promise.all(associationPromises)

              forEach(data[TYPE.AGENT_PROFILE], (profile, i) => {
                data[TYPE.AGENT_PROFILE][i][TYPE.AGENT_CLIENT_ASSN] = results[i * 2]
                data[TYPE.AGENT_PROFILE][i][TYPE.AGENT_MORTGAGE_DEAL_ASSN] = results[(i * 2) + 1]
              })
            }
            catch (error) {
              console.error(`Error during association fetches for agent ${agent_id}, profile ${profile.id}: ${error}`)
              throw error
            }
          }
          catch(error) {
            throw `Error fetching secondary data: ${error}`
          }
          return data
        })
        .then(data => {
          // Create, cache, and return the Agent.
          let agent = new Agent(data)
          commit('cacheAgent', { agent, agent_id, })

          return agent
        })
        .catch(error => {
          if (error.status_code !== 404) {
            throw 'Error fetching Agent object: ' + error
          }

          return null
        })
    },

    fetchAgents ({ dispatch, commit, getters }, { force = false }) {
      // If the agent list is cached or we're not forcing the fetch, return the cached agent list.
      if (getters.hasCachedAgentList() && !force) {
        return getters.getCachedAgentList()
      }
      
      // Otherwise, fetch it, cache the new agent list, and return it to the caller.
      return dispatch('fetchList', { type: TYPE.AGENT, })
        .then(list => {
          commit('cacheAgentList', { list, })
          return list
        })
        .catch(error => {
          throw 'Error fetching agent list: ' + error
        })
      },
    },
}

export default agent