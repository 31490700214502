import { getField, updateField } from 'vuex-map-fields'

import { VALID_TABLES, } from '../../constants'
import ValidService from '../../services/api/valid.service'

const valid = {
  namespaced: true,
  state: {
    initialized: false,

    [VALID_TABLES.AGENT_TEAM]: [],
    [VALID_TABLES.AGENT_PROFILE]: [],
    [VALID_TABLES.COMMENT_TYPE]: [],
    [VALID_TABLES.LANGUAGE]: [],
    [VALID_TABLES.MORTGAGE_DEAL_STATUS]: [],
    [VALID_TABLES.PROGRAM]: [],
    [VALID_TABLES.PROGRAM_ELEMENT]: [],
    [VALID_TABLES.PROGRAM_ELEMENT_FREQUENCY]: [],
    [VALID_TABLES.PROVINCE]: [],
    [VALID_TABLES.SOCIAL_MEDIA]: [],
  },
  getters: {
    getField,
    
    getData: state => type => state[type],
    isCached: (state) => type => state[type].length > 0,
    isInitialized: (state) => state.initialized,
  },
  mutations: {
    updateField,
    
    setData (state, { type, data }) {
      state[type] = data
    },
    setInitialized (state) {
      state.initialized = true
    },
  },
  actions: {
    async initStore ({ commit, getters, dispatch }) {
      if (!getters.isInitialized) {
        let promises = []

        for (const table in VALID_TABLES) {
          promises.push(dispatch('fetchList', { type: VALID_TABLES[table], }))
        }

        try {
          await Promise.all(promises)
          commit('setInitialized')
        }
        catch(error) {
          throw error
        }
      }
    },

    createData ({ commit }, { type, payload }) {
      return ValidService.create({ type, }, payload)
    },

    deleteData ({ commit }, { type, primary_key }) {
      return ValidService.delete({ type, primary_key, })
    },

    fetchList ({ commit, getters }, { type, force = false }) {
      if (getters.isCached(type) && !force) {
        return getters.getData(type)
      }

      return ValidService.list({ type, })
        .then(data => {
          commit('setData', { type, data, })
          return data
        })
        .catch(error => {
          throw error
        })
    },

    updateData ({ commit }, { type, primary_key, payload }) {
      return ValidService.update({ type, primary_key, }, payload)
    },
  },
}

export default valid