<template>
  <v-app-bar
    app
    color="primary"
    dark
    dense
    flat
  >
    <v-app-bar-nav-icon @click="$emit('display-nav-bar')" />

    <v-toolbar-title class="pl-0">
      <div
        v-ripple
        @click="pushToDashboard"
        class="py-2 px-4"
        style="cursor: pointer;"
      >
        <span class="text-h5"><strong>iCare</strong> Dashboard</span>
      </div>
    </v-toolbar-title>

    <v-spacer></v-spacer>

  </v-app-bar>
</template>

<script>
import { ICONS, ROUTES } from '../../constants'
import { push } from '../../router'

export default {
  name: "AppBarMobile",
  data: () => ({

  }),
  methods: {
    initTemplateConstants () {
      this.ACCOUNT_SETTINGS_ICON = ICONS.ACCOUNT_SETTINGS
      this.MONITOR_ICON = ICONS.MONITOR
    },
    pushToAccount () {
      push(ROUTES.ACCOUNT)
    },
    pushToDashboard () {
      push(ROUTES.DASHBOARD)
    }
  },
  created() {
    this.initTemplateConstants()
  }
}
</script>
