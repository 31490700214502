import DateFieldConfig from './DateFieldConfig'
import SelectFieldConfig from './SelectFieldConfig'
import TextFieldConfig from './TextFieldConfig'

/**
 * Factory for creating FieldConfigs based on a data-type and property.
 */
class FieldConfigFactory {

  constructor () {}

  /**
   * Create a TextFieldConfig object
   * @param {string} property Data-type property represented by the field.
   * @param {string} label Label of the field.
   * @param {string} smColumns Number of columns (1-12) that this field spans at the "sm" breakpoint or larger.
   * @param {string} mdColumns Number of columns (1-12) that this field spans at the "md" breakpoint or larger.
   * @param {string} iconPrepend Icon to prepend the field with. Default is null.
   * @param {boolean} required Indicate that the field requires a value.
   * @param {number} counter Number of characters this field can support.
   * @param {array} rules Array of validation rules for this field.
   */
  makeTextFieldConfig ({ property, label, smColumns = 12, mdColumns = 12, iconPrepend = null, required = true, counter, rules }) {
    return new TextFieldConfig({ property, label, smColumns, mdColumns, iconPrepend, required, counter, rules, })
  }

  /**
   * Create a DateFieldConfig object
   * @param {string} property Data-type property represented by the field.
   * @param {string} label Label of the field.
   * @param {string} smColumns Optional number of columns (1-12) that this field spans at the "sm" breakpoint or larger. Default is 12.
   * @param {string} mdColumns Optional number of columns (1-12) that this field spans at the "md" breakpoint or larger. Default is 12.
   * @param {string} iconPrepend Optional icon to prepend the field with. Default is null.
   */
  makeDateFieldConfig ({ property, label, smColumns = 12, mdColumns = 12, iconPrepend = null, required = true, rules }) {
    return new DateFieldConfig({ property, label, smColumns, mdColumns, iconPrepend, required, rules, })
  }

  /**
   * Create a SelectFieldConfig object
   * @param {string} property Data-type property represented by the field.
   * @param {string} label Label of the field.
   * @param {string} smColumns Optional number of columns (1-12) that this field spans at the "sm" breakpoint or larger. Default is 12.
   * @param {string} mdColumns Optional number of columns (1-12) that this field spans at the "md" breakpoint or larger. Default is 12.
   * @param {string} iconPrepend Optional icon to prepend the field with. Default is null.
   * @param {string} textProperty Optionally display the text of this property. Use when the select is populated with objects. Default is null.
   * @param {string} items Items to populate the select element with.
   */
  makeSelectFieldConfig ({ property, label, smColumns = 12, mdColumns = 12, iconPrepend = null, textProperty = null, items = null, required = true, }) {
    return new SelectFieldConfig({ property, label, smColumns, mdColumns, iconPrepend, textProperty, items, required, })
  }
}

const fieldConfigFactory = new FieldConfigFactory()
export default fieldConfigFactory