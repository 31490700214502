<template>
  <v-snackbars :objects.sync="messages" app bottom :max-width="width" right text outlined>
    <template v-slot:default="{ message }">
      {{ message.text }}
    </template>
    <template v-slot:action="{ close, message }">
      <v-btn
        v-show="message.dismissable"
        @click="close"
        :color="message.buttonColor"
        icon
      >
        <v-icon>{{ CLOSE_ICON }}</v-icon>
      </v-btn>
    </template>
  </v-snackbars> 
</template>

<script>
import VSnackbars from 'v-snackbars'
import { mapFields } from 'vuex-map-fields'
import { ICONS } from '../../constants'

export default {
  components: {
    VSnackbars
  },
  computed: {
    ...mapFields('message', ['messages']),
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.$vuetify.breakpoint.width - this.$vuetify.breakpoint.scrollBarWidth - 10
        case 'sm': return (this.$vuetify.breakpoint.width - this.$vuetify.breakpoint.scrollBarWidth) / 2
        default: return (this.$vuetify.breakpoint.width - this.$vuetify.breakpoint.scrollBarWidth) / 4
      }
    },
  },
  created() {
    this.CLOSE_ICON = ICONS.CLOSE
  }
}
</script>