import { filter, isArray, isEmpty, isNil, reject } from "lodash"

import { ICONS, YES_OR_NO } from "../../constants"
import DataTransferObject from "./DataTransferObject"
import FieldConfigFactory from "../field_configs/FieldConfigFactory"
import ValidationRulesFactory from "../validation_rules/ValidationRulesFactory"

// MortgageDeal types.
export const TYPE = {
  MORTGAGE: 'mortgage',
  MORTGAGE_DEAL: 'mortgage_deal',
  MORTGAGE_DEAL_AGENT_ASSN: 'mortgage_deal_agent_assn',
  MORTGAGE_DEAL_CLIENT_ASSN: 'mortgage_deal_client_assn',
  MORTGAGE_DEAL_COMMENT: 'mortgage_deal_comment',
  MORTGAGE_PROPERTY: 'mortgage_property',
  MORTGAGE_DEAL_PROGRAM_CONTROL: 'mortgage_deal_program_control',
}

// MortgageDeal-type properties.
export const PROPERTY = {
  ADDRESS: 'address',
  CITY: 'city',
  CLOSING_DATE: 'closing_date',
  COMMENT: 'comment',
  COMMENT_ID: 'comment_id',
  COMMENT_TYPE: 'comment_type',
  DATE_CREATED: 'date_created',
  DATE_PAID: 'date_paid',
  FEE: 'fee',
  ID: 'id',
  LANGUAGE: 'language',
  LOAN_CODE: 'loan_code',
  MATURITY_DATE: 'maturity_date',
  MORTGAGE_DEAL_ID: 'mortgage_deal_id',
  MORTGAGE_ID: 'mortgage_id',
  POSTAL_CODE: 'postal_code',
  PROGRAM: 'program',
  PROVINCE: 'province',
  REFERRAL_COMPANY: 'referral_company',
  STATUS: 'status',
  TERM_END_DATE: 'term_end_date',
  TERM_LENGTH_YEARS: 'term_length_years',
  TERM_START_DATE: 'term_start_date',
  // Mortgage program control
  BATCH: 'batch',
  RESEND_TYC: 'resend_tyc',
  RETURN_BA: 'return_ba',
  RETURN_TYC: 'return_tyc',
  RETURN_ML: 'return_ml',
  RETURN_CAL: 'return_cal',
  SENT_TYC: 'sent_tyc',
  SENT_ML: 'sent_ml',
  SUPPRESS_TYC: 'suppress_tyc',
  SUPPRESS_ML: 'suppress_ml',
  SUPPRESS_BA: 'suppress_ba',
  SUPPRESS_MAG: 'suppress_mag',
  SUPPRESS_CAL: 'suppress_cal',
  SUPPRESS_BD: 'suppress_bd',
  INCLUDE_CALENDAR: 'include_calendar',
  HOLD: 'hold',
  MESSAGE: 'message',
}

// Mapping of MortgageDeal PROPERTY values to validation properties.
// These are used as keys in the VALIDATION_RULES.
export const VALIDATE_PROPERTY = {
  [PROPERTY.ADDRESS]: 'mortgage_property_address',
  [PROPERTY.CITY]: 'mortgage_property_city',
  [PROPERTY.CLOSING_DATE]: 'closing_date',
  [PROPERTY.COMMENT]: 'comment',
  [PROPERTY.COMMENT_ID]: 'comment_id',
  [PROPERTY.COMMENT_TYPE]: 'comment_type',
  [PROPERTY.DATE_CREATED]: 'mortgage_deal_date_created',
  [PROPERTY.DATE_PAID]: 'mortgage_deal_date_paid',
  [PROPERTY.FEE]: 'mortgage_deal_fee',
  [PROPERTY.ID]: 'id',
  [PROPERTY.LANGUAGE]: 'mortgage_deal_language',
  [PROPERTY.LOAN_CODE]: 'loan_code',
  [PROPERTY.MATURITY_DATE]: 'maturity_date',
  [PROPERTY.MORTGAGE_DEAL_ID]: 'mortgage_deal_id',
  [PROPERTY.MORTGAGE_ID]: 'mortgage_id',
  [PROPERTY.POSTAL_CODE]: 'mortgage_property_postal_code',
  [PROPERTY.PROGRAM]: 'mortgage_deal_program',
  [PROPERTY.PROVINCE]: 'mortgage_property_province',
  [PROPERTY.REFERRAL_COMPANY]: 'mortgage_deal_referral_company',
  [PROPERTY.STATUS]: 'mortgage_deal_status',
  [PROPERTY.TERM_END_DATE]: 'mortgage_deal_term_end_date',
  [PROPERTY.TERM_LENGTH_YEARS]: 'mortgage_deal_term_length_years',
  [PROPERTY.TERM_START_DATE]: 'mortgage_deal_term_start_date',
  [PROPERTY.BATCH]: 'mortgage_deal_batch',
  [PROPERTY.RESEND_TYC]: 'mortgage_deal_resend_tyc',
  [PROPERTY.RETURN_BA]: 'mortgage_deal_return_ba',
  [PROPERTY.RETURN_TYC]: 'mortgage_deal_return_tyc',
  [PROPERTY.RETURN_ML]: 'mortgage_deal_return_ml',
  [PROPERTY.RETURN_CAL]: 'mortgage_deal_return_cal',
  [PROPERTY.SENT_TYC]: 'mortgage_deal_sent_tyc',
  [PROPERTY.SENT_ML]: 'mortgage_deal_sent_ml',
  [PROPERTY.SUPPRESS_TYC]: 'mortgage_deal_suppress_tyc',
  [PROPERTY.SUPPRESS_ML]: 'mortgage_deal_suppress_ml',
  [PROPERTY.SUPPRESS_BA]: 'mortgage_deal_suppress_ba',
  [PROPERTY.SUPPRESS_MAG]: 'mortgage_deal_suppress_mag',
  [PROPERTY.SUPPRESS_CAL]: 'mortgage_deal_suppress_cal',
  [PROPERTY.SUPPRESS_BD]: 'mortgage_deal_suppress_bd',
  [PROPERTY.INCLUDE_CALENDAR]: 'mortgage_deal_include_calendar',
  [PROPERTY.HOLD]: 'mortgage_deal_hold',
  [PROPERTY.MESSAGE]: 'mortgage_deal_message',
}

// Default values for primary and secondary MortgageDeal data-types.
const DEFAULTS = {
  [TYPE.MORTGAGE]: { default: true, mortgage_id: '', operator_id: '', dlc: '', loan_code: '', closing_date: '', maturity_date: '', },
  [TYPE.MORTGAGE_DEAL]: { default: true, mortgage_deal_id: '', operator_id: '', dlc: '', mortgage_id: '', date_created: '', date_paid: '', fee: '', language: 'EN', program: '', referral_company: '', status: '', term_end_date: '', term_length_years: '', term_start_date: '', },
  [TYPE.MORTGAGE_DEAL_AGENT_ASSN]: [],
  [TYPE.MORTGAGE_DEAL_CLIENT_ASSN]: [],
  [TYPE.MORTGAGE_DEAL_COMMENT]: [],
  [TYPE.MORTGAGE_PROPERTY]: { default: true, id: '', mortgage_id: '', operator_id: '', dlc: '', address: '', city: '', postal_code: '', province: 'AB', country: '', },
  [TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: { default: true, id: '', mortgage_deal_id: '', operator_id: '', dlc: '', batch: '', resend_tyc: '', return_ba: '', return_tyc: '', return_ml: '', return_cal: '', sent_tyc: 'N', sent_ml: 'N', suppress_tyc: 'N', suppress_ml: 'N', suppress_ba: 'N', suppress_mag: 'N', suppress_cal: 'N', suppress_bd: 'N', include_calendar: 'N', hold: 'N', message: '', },
}

// Defaut values for iterable MortgageDeal objects.
const DEFAULT_ITEM = {
  [TYPE.MORTGAGE_DEAL_COMMENT]: { default: true, comment: '', comment_type: '', dlc: '', operator_id: '', mortgage_deal_id: '', },
}

// Validation rules applied to form fields and CSV files.
export const VALIDATION_RULES = {
  [TYPE.MORTGAGE]: {
    [PROPERTY.MORTGAGE_ID]: ValidationRulesFactory.makeIntRules(),
    [VALIDATE_PROPERTY[PROPERTY.LOAN_CODE]]: ValidationRulesFactory.makeStringRules({ length: 30, nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.CLOSING_DATE]]: ValidationRulesFactory.makeDateRules({ nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.MATURITY_DATE]]: ValidationRulesFactory.makeDateRules({ nullable: true, }),
  },
  [TYPE.MORTGAGE_PROPERTY]: {
    [VALIDATE_PROPERTY[PROPERTY.ADDRESS]]: ValidationRulesFactory.makeStringRules({ length: 100, }),
    [VALIDATE_PROPERTY[PROPERTY.CITY]]: ValidationRulesFactory.makeStringRules({ length: 80, }),
    [VALIDATE_PROPERTY[PROPERTY.POSTAL_CODE]]: ValidationRulesFactory.makePostalCodeRules(),
    [VALIDATE_PROPERTY[PROPERTY.PROVINCE]]: ValidationRulesFactory.makeProvinceRules(),
  },
  [TYPE.MORTGAGE_DEAL]: {
    [VALIDATE_PROPERTY[PROPERTY.MORTGAGE_DEAL_ID]]: ValidationRulesFactory.makeIntRules(),
    [VALIDATE_PROPERTY[PROPERTY.MORTGAGE_ID]]: ValidationRulesFactory.makeIntRules(),
    [VALIDATE_PROPERTY[PROPERTY.DATE_CREATED]]: ValidationRulesFactory.makeDateRules(),
    [VALIDATE_PROPERTY[PROPERTY.DATE_PAID]]: ValidationRulesFactory.makeDateRules({ nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.FEE]]: ValidationRulesFactory.makeDecimal6_2Rules(),
    [VALIDATE_PROPERTY[PROPERTY.LANGUAGE]]: ValidationRulesFactory.makeLanguageRules(),
    [VALIDATE_PROPERTY[PROPERTY.PROGRAM]]: ValidationRulesFactory.makeStringRules({ length: 25,}),
    [VALIDATE_PROPERTY[PROPERTY.REFERRAL_COMPANY]]: ValidationRulesFactory.makeStringRules({ length: 100, nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.STATUS]]: ValidationRulesFactory.makeStringRules({ length: 25,}),
    [VALIDATE_PROPERTY[PROPERTY.TERM_END_DATE]]: ValidationRulesFactory.makeDateRules(),
    [VALIDATE_PROPERTY[PROPERTY.TERM_LENGTH_YEARS]]: ValidationRulesFactory.makeTinyIntRules(),
    [VALIDATE_PROPERTY[PROPERTY.TERM_START_DATE]]: ValidationRulesFactory.makeDateRules(),
  },
  [TYPE.MORTGAGE_DEAL_COMMENT]: {
    [VALIDATE_PROPERTY[PROPERTY.COMMENT]]: ValidationRulesFactory.makeStringRules({ length: 100, }),
    [VALIDATE_PROPERTY[PROPERTY.COMMENT_TYPE]]: ValidationRulesFactory.makeStringRules({ length: 10, }),
  },
  [TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: {
    [VALIDATE_PROPERTY[PROPERTY.MORTGAGE_DEAL_ID]]: ValidationRulesFactory.makeIntRules(),
    [VALIDATE_PROPERTY[PROPERTY.BATCH]]: ValidationRulesFactory.makeStringRules({ length: 10, }),
    [VALIDATE_PROPERTY[PROPERTY.RESEND_TYC]]: ValidationRulesFactory.makeStringRules({ length: 10, nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.RETURN_BA]]: ValidationRulesFactory.makeStringRules({ length: 100, nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.RETURN_TYC]]: ValidationRulesFactory.makeStringRules({ length: 100, nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.RETURN_ML]]: ValidationRulesFactory.makeStringRules({ length: 100, nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.RETURN_CAL]]: ValidationRulesFactory.makeStringRules({ length: 100, nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.SENT_TYC]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.SENT_ML]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.SUPPRESS_TYC]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.SUPPRESS_ML]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.SUPPRESS_BA]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.SUPPRESS_MAG]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.SUPPRESS_CAL]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.SUPPRESS_BD]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.INCLUDE_CALENDAR]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.HOLD]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.MESSAGE]]: ValidationRulesFactory.makeStringRules({ length: 100, nullable: true, }),
  },
}

/**
 * Get the length rule value from a validation rule.
 * @param {string} type MortgageDeal.TYPE
 * @param {*} property MortgageDeal.VALIDATE_PROPERTY
 * @returns Length rule value.
 */
const getLength = (type, property) => {
  if ('length' in VALIDATION_RULES[type][property]) {
    return VALIDATION_RULES[type][property].length
  }
  throw new ReferenceError(`Rule for ${type}.${property} does not have a 'length' property.`)
}

/**
 * Get rules array from a validation rule.
 * @param {string} type MortgageDeal.TYPE
 * @param {*} property MortgageDeal.VALIDATE_PROPERTY
 * @returns Length rule value.
 */
const getRules = (type, property) => {
  if ('rules' in VALIDATION_RULES[type][property]) {
    return VALIDATION_RULES[type][property].rules
  }
  throw new ReferenceError(`Rule for ${type}.${property} does not have a 'rules' property.`)
}

/**
 * Get specific rules for a text field config.
 * @param {string} type MortgageDeal.TYPE
 * @param {*} property MortgageDeal.VALIDATE_PROPERTY
 * @returns Object containing the counter and rules for a text field.
 */
const getTextRules = (type, property) => {
  const validationProperty = VALIDATE_PROPERTY[property]
  return {
    property,
    counter: getLength(type, validationProperty),
    rules: getRules(type, validationProperty),
  }
}

/**
 * Get specific rules for a date field config.
 * @param {string} type MortgageDeal.TYPE
 * @param {*} property MortgageDeal.VALIDATE_PROPERTY
 * @returns Object containing the counter and rules for a text field.
 */
const getDateRules = (type, property) => {
  const validationProperty = VALIDATE_PROPERTY[property]
  return {
    property,
    rules: getRules(type, validationProperty),
  }
}


/**
* Determine if members of the specified type are iterable.
* @param {string} type MortgageDeal.TYPE 
* @returns True if the data-type is iterable, false if the datat-type is a single object.
*/
export const isTypeIterable = (type) => Array.isArray(DEFAULTS[type])

// MortgageDeal field metadata.
const FIELD_METADATA = {
  [TYPE.MORTGAGE]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE, PROPERTY.LOAN_CODE), mdColumns: 6, label: 'Loan Code', required: false, }),
        FieldConfigFactory.makeDateFieldConfig({ ...getDateRules(TYPE.MORTGAGE, PROPERTY.CLOSING_DATE), smColumns: 6, mdColumns: 3, label: 'Closing Date', required: false, }),
        FieldConfigFactory.makeDateFieldConfig({ ...getDateRules(TYPE.MORTGAGE, PROPERTY.MATURITY_DATE), smColumns: 6, mdColumns: 3, label: 'Maturity Date', required: false, }),
      ],
    ],
  },
  [TYPE.MORTGAGE_PROPERTY]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_PROPERTY, PROPERTY.ADDRESS), label: 'Address', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_PROPERTY, PROPERTY.CITY), smColumns: 6, mdColumns: 4, label: 'City', }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 3, mdColumns: 2, label: 'Province', property: PROPERTY.PROVINCE, textProperty: PROPERTY.PROVINCE, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_PROPERTY, PROPERTY.POSTAL_CODE), smColumns: 3, mdColumns: 2, label: 'Postal Code', }),
      ],
    ],
  },
  [TYPE.MORTGAGE_DEAL]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL, PROPERTY.MORTGAGE_DEAL_ID), mdColumns: 6, label: 'Mortgage Deal ID', }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 6, mdColumns: 4, label: 'Status', property: PROPERTY.STATUS, textProperty: PROPERTY.STATUS, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 6, mdColumns: 4, label: 'Program', property: PROPERTY.PROGRAM, textProperty: PROPERTY.PROGRAM, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 6, mdColumns: 4, label: 'Language', property: PROPERTY.LANGUAGE, textProperty: PROPERTY.LANGUAGE, }),
        FieldConfigFactory.makeDateFieldConfig({ ...getDateRules(TYPE.MORTGAGE_DEAL, PROPERTY.DATE_CREATED), smColumns: 6, mdColumns: 4, label: 'Date Created', }),
        FieldConfigFactory.makeDateFieldConfig({ ...getDateRules(TYPE.MORTGAGE_DEAL, PROPERTY.DATE_PAID), smColumns: 6, mdColumns: 4, label: 'Date Paid', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL, PROPERTY.FEE), smColumns: 6, mdColumns: 4, label: 'Fee', iconPrepend: ICONS.CURRENCY, }),
        FieldConfigFactory.makeDateFieldConfig({ ...getDateRules(TYPE.MORTGAGE_DEAL, PROPERTY.TERM_START_DATE), smColumns: 6, mdColumns: 4, label: 'Term Start Date', }),
        FieldConfigFactory.makeDateFieldConfig({ ...getDateRules(TYPE.MORTGAGE_DEAL, PROPERTY.TERM_END_DATE), smColumns: 6, mdColumns: 4, label: 'Term End Date', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL, PROPERTY.TERM_LENGTH_YEARS), smColumns: 6, mdColumns: 4, label: 'Term Length (Years)', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL, PROPERTY.REFERRAL_COMPANY), label: 'Referral Company', required: false, }),
      ],
    ],
  },
  [TYPE.MORTGAGE_DEAL_COMMENT]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeSelectFieldConfig({ label: 'Comment Type', property: PROPERTY.COMMENT_TYPE, textProperty: PROPERTY.COMMENT_TYPE, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL_COMMENT, PROPERTY.COMMENT), label: 'Comment', }),
      ],
    ],
  },
  [TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, PROPERTY.BATCH), smColumns: 6, mdColumns: 6, label: 'Batch', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, PROPERTY.RESEND_TYC), smColumns: 6, mdColumns: 6, label: 'Resend TYC', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, PROPERTY.RETURN_BA), label: 'Return BA', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, PROPERTY.RETURN_TYC), label: 'Return TYC', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, PROPERTY.RETURN_ML), label: 'Return ML', required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, PROPERTY.RETURN_CAL), label: 'Return CAL', required: false, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Sent TYC', property: PROPERTY.SENT_TYC, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Suppress TYC', property: PROPERTY.SUPPRESS_TYC, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Sent ML', property: PROPERTY.SENT_ML, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Suppress ML', property: PROPERTY.SUPPRESS_ML, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Suppress BA', property: PROPERTY.SUPPRESS_BA, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Suppress MAG', property: PROPERTY.SUPPRESS_MAG, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Suppress CAL', property: PROPERTY.SUPPRESS_CAL, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Suppress BD', property: PROPERTY.SUPPRESS_BD, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Include Calendar', property: PROPERTY.INCLUDE_CALENDAR, items: YES_OR_NO, }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Hold', property: PROPERTY.HOLD, items: YES_OR_NO, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, PROPERTY.MESSAGE), label: 'Message', required: false, }),
      ],
    ],
  },
}

/**
 * Set valid data for select fields.
 * @param {*} object An object containing arrays of valid data.
 */
export const setSelectValidData = ({ provinces = null, statuses = null, programs = null, languages = null, commentTypes = null }) => {
  if (statuses) {
    FIELD_METADATA[TYPE.MORTGAGE_DEAL].fieldGroups[0][1].items = statuses
  }
  
  if (programs) {
    FIELD_METADATA[TYPE.MORTGAGE_DEAL].fieldGroups[0][2].items = programs
  }
  
  if (languages) {
    FIELD_METADATA[TYPE.MORTGAGE_DEAL].fieldGroups[0][3].items = languages
  }
  
  if (provinces) {
    FIELD_METADATA[TYPE.MORTGAGE_PROPERTY].fieldGroups[0][2].items = provinces
  }
  
  if (commentTypes) {
    FIELD_METADATA[TYPE.MORTGAGE_DEAL_COMMENT].fieldGroups[0][0].items = commentTypes
  }
}


export default class MortgageDeal extends DataTransferObject {
  /**
   * Create a MortgageDeal from a DTO.
   * @param {*} dto DTO containing the primary and secondary data types of a MortgageDeal.
   */
  constructor (dto) {
    super()

    if (!isNil(dto)) {
      this.setMortgageDealData(dto[TYPE.MORTGAGE_DEAL])
      this.setCommentData(dto[TYPE.MORTGAGE_DEAL_COMMENT])
      this.setMortgageData(dto[TYPE.MORTGAGE])
      this.setProgramControlData(dto[TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL])
      this.setPropertyData(dto[TYPE.MORTGAGE_PROPERTY])
      this.setAgentAssnData(dto[TYPE.MORTGAGE_DEAL_AGENT_ASSN])
      this.setClientAssnData(dto[TYPE.MORTGAGE_DEAL_CLIENT_ASSN])

      super.setEdit(false, TYPE.MORTGAGE_DEAL)
      super.setEdit(false, TYPE.MORTGAGE)
      super.setEdit(false, TYPE.MORTGAGE_PROPERTY)
      super.setEdit(false, TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL)
    }

    this.metadata = FIELD_METADATA
  }

  /**
   * Append data to the specified type.
   * @param {*} data 
   * @param {string} type MortgageDeal.TYPE
   */
  appendData (data, type) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`MortgageDeal data-type '${type}' is not iterable.`)
    }
    super.appendData(data, type)
  }

  /**
   * Disable editing of this MortgageDeal and restore the type's data object to a pre-edit state.
   * @param {string} type MortgageDeal.TYPE
   */
  cancelEdit (type) {
    this.disableEdit(type)
    super.restoreData(type)
  }

  /**
   * Delete a member item from the specified iterable type.
   * @param {*} item Item to delete.
   * @param {*} type 
   */
  deleteData (item, type) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`MortgageDeal data-type '${type}' is not iterable.`)
    }
    
    let newData = null

    switch (type) {
      case TYPE.MORTGAGE_DEAL_AGENT_ASSN:
        if (!item.agent_id || !item.profile_id || !item.mortgage_deal_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, profile_id, mortgage_deal_id].`)
        }
        newData = reject(super.getData(type), { agent_id: item.agent_id, profile_id: item.profile_id, mortgage_deal_id: item.mortgage_deal_id, })
        this.setAgentAssnData(newData)
        break

      case TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
        if (!item.mortgage_deal_id || !item.client_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [mortgage_deal_id, client_id].`)
        }
        newData = reject(super.getData(type), { mortgage_deal_id: item.mortgage_deal_id, client_id: item.client_id, })
        this.setClientAssnData(newData)
        break

      default:
        throw new TypeError(`MortgageDeal data-type '${type}' does not have member item deletion implemented.`)
    }
  }
  
  /**
   * Disable editing of the specified type.
   * @param {string} type MortgageDeal.TYPE
   */
  disableEdit (type) {
    super.setEdit(false, type)
  }

  /**
   * Enable editing of the specified type and save the state of the type's data object.
   * @param {string} type MortgageDeal.TYPE
   */
  enableEdit (type) {
    super.saveData(type)
    super.setEdit(true, type)
  }

  /**
   * Determine if a specific type is being edited.
   * @param {string} type MortgageDeal.TYPE
   * @returns True if that type is editing, false otherwise.
   */
  isEditing (type) {
    return super.getEdit(type)
  }

  getAgentAssociationCount () {
    return super.getData(TYPE.MORTGAGE_DEAL_AGENT_ASSN).length
  }

  getClientAssociationCount () {
    return super.getData(TYPE.MORTGAGE_DEAL_CLIENT_ASSN).length
  }

  getCommentCount () {
    return super.getData(TYPE.MORTGAGE_DEAL_COMMENT).length
  }

  getDefault(type) {
    return DEFAULTS[type]
  }

  getDefaultItem(type) {
    return DEFAULT_ITEM[type]
  }

  /**
   * Get the field groups of the specified type.
   * @param {string} type MortgageDeal.TYPE
   * @returns Array of field groups object.
   */
  getFieldGroups (type) {
    return this.metadata[type].fieldGroups
  }

  /**
   * Get the member from the specified iterable type that equals the item.
   * @param {string} type An iterable MortgageDeal.TYPE
   * @param {*} item An object of specified-type.
   * @returns Data object of iterable type.
   */
  getIterableData (type, item) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`MortgageDeal data-type '${type}' is not iterable. Use MortgageDeal.getData() instead.`)
    }

    switch (type) {
      case TYPE.MORTGAGE_DEAL_COMMENT:
        if (!item.comment_id || !item.mortgage_deal_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [comment_id, mortgage_deal_id].`)
        }
        return filter(super.getData(type), { comment_id: item.comment_id, mortgage_deal_id: item.mortgage_deal_id, })

      case TYPE.MORTGAGE_DEAL_AGENT_ASSN:
        if (!item.agent_id || !item.profile_id || !item.mortgage_deal_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, profile_id, mortgage_deal_id].`)
        }
        return filter(super.getData(type), { agent_id: item.agent_id, profile_id: item.profile_id, mortgage_deal_id: item.mortgage_deal_id, })

      case TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
        if (!item.mortgage_deal_id || !item.client_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [mortgage_deal_id, client_id].`)
        }
        return filter(super.getData(type), { mortgage_deal_id: item.mortgage_deal_id, client_id: item.client_id, })

      default:
        throw new TypeError(`MortgageDeal data-type '${type}' does not have getIterableData() implemented.`)
    }
  }

  getMortgageProperty (property) {
    return super.getProperty(TYPE.MORTGAGE, property)
  }

  getMortgageDealProperty (property) {
    return super.getProperty(TYPE.MORTGAGE_DEAL, property)
  }

  getProgramControlProperty (property) {
    return super.getProperty(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL, property)
  }

  getPropertyProperty (property) {
    return super.getProperty(TYPE.MORTGAGE_PROPERTY, property)
  }

  /**
   * Get a string containing a summary of a MortgageDeal data-type.
   * @param {string} type MortgageDeal.TYPE
   * @returns A summary of that data-type.
   */
  getSummaryText (type) {
    switch(type) {
      case TYPE.MORTGAGE_DEAL:
        let paidText = this.getMortgageDealProperty(PROPERTY.DATE_PAID) ? `${this.getMortgageDealProperty(PROPERTY.DATE_PAID)} (paid)` : `no payment`
        return `${this.getMortgageDealProperty(PROPERTY.PROGRAM)} ∙ ${this.getMortgageDealProperty(PROPERTY.STATUS)} ∙ ${paidText}`

      case TYPE.MORTGAGE_PROPERTY:
        const property = `${this.getPropertyProperty(PROPERTY.ADDRESS)}, ${this.getPropertyProperty(PROPERTY.CITY)} ∙ ${this.getPropertyProperty(PROPERTY.PROVINCE)} ∙ ${this.getPropertyProperty(PROPERTY.POSTAL_CODE)}`
        return this.hasProperty() ? property : 'No property information.'

      case TYPE.MORTGAGE:
        let mortgageText
        if (this.hasMortgage()) {
            let closingDateText = this.getMortgageProperty(PROPERTY.CLOSING_DATE) ? `${this.getMortgageProperty(PROPERTY.CLOSING_DATE)} (closing)` : `no closing`
            let maturityDateText = this.getMortgageProperty(PROPERTY.MATURITY_DATE) ? `${this.getMortgageProperty(PROPERTY.MATURITY_DATE)} (maturity)` : `no maturity`
            mortgageText = `${this.getMortgageProperty(PROPERTY.LOAN_CODE)} ∙ ${closingDateText} ∙ ${maturityDateText}`
        }
        else {
            mortgageText = 'No mortgage information.'
        }
        return mortgageText

      case TYPE.MORTGAGE_DEAL_AGENT_ASSN:
        return this.getAgentAssociationCount().toString()

      case TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
        return this.getClientAssociationCount().toString()

      case TYPE.MORTGAGE_DEAL_COMMENT:
        return this.getCommentCount().toString()
      
      case TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL:
        return `Batch ${this.getProgramControlProperty(PROPERTY.BATCH)}`
    }
  }

  /**
   * Checks if the MortgageDeal has an address data object.
   * @returns True if the address is non-empty, false otherwise.
   */
  hasProperty () {
    return !isEmpty(super.getData(TYPE.MORTGAGE_PROPERTY))
  }

  /**
   * Checks if the MortgagelDeal has a contact data object.
   * @returns True if the contact is non-empty, false otherwise.
   */
  hasMortgage () {
    return !isEmpty(super.getData(TYPE.MORTGAGE))
  }

  /**
   * Prepend data to the specified type.
   * @param {*} data 
   * @param {string} type MortgageDeal.TYPE
   */
   prependData (data, type) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`MortgageDeal data-type '${type}' is not iterable.`)
    }
    super.prependData(data, type)
  }

  /**
   * Sets the agent association data for this MortageDeal.
   * @param {*} data 
   */
  setAgentAssnData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.MORTGAGE_DEAL_AGENT_ASSN]
    }
    super.setData(data, TYPE.MORTGAGE_DEAL_AGENT_ASSN)
  }

  /**
   * Sets the client association data for this MortgageDeal.
   * @param {*} data 
   */
  setClientAssnData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.MORTGAGE_DEAL_CLIENT_ASSN]
    }
    super.setData(data, TYPE.MORTGAGE_DEAL_CLIENT_ASSN)
  }

  /**
   * Sets the mortgage deal comment data for this MortgageDeal.
   * @param {*} data 
   */
  setCommentData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.MORTGAGE_DEAL_COMMENT]
    }
    super.setData(data, TYPE.MORTGAGE_DEAL_COMMENT)
  }

  /**
   * Sets the mortgage data for this MortgageDeal. Converts the data into a single object if wrapped in an array.
   * @param {*} data 
   */
  setMortgageData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.MORTGAGE]
    }
    super.setData(data, TYPE.MORTGAGE)
  }

  /**
   * Sets the mortgage deal data for this MortgageDeal. Converts the data into a single object if wrapped in an array.
   * @param {*} data 
   */
  setMortgageDealData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.MORTGAGE_DEAL]
    }
    super.setData(data, TYPE.MORTGAGE_DEAL)
  }

  /**
   * Sets the program control data for this MortgageDeal. Converts the data into a single object if wrapped in an array.
   * @param {*} data 
   */
  setProgramControlData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]
    }
    super.setData(data, TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL)
  }

  /**
   * Sets the property data for this MortgageDeal. Converts the data into a single object if wrapped in an array.
   * @param {*} data 
   */
  setPropertyData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.MORTGAGE_PROPERTY]
    }
    super.setData(data, TYPE.MORTGAGE_PROPERTY)
  }

  /**
   * Set the data object for a specified type.
   * @override DataTransferObject.setData()
   * @param {*} data 
   * @param {string} type MortgageDeal.TYPE
   */
  setData(data, type) {
    switch (type) {
      case TYPE.MORTGAGE_DEAL:
        this.setMortgageDealData(data)
        break
      case TYPE.MORTGAGE_PROPERTY:
        this.setPropertyData(data)
        break
      case TYPE.MORTGAGE_DEAL_AGENT_ASSN:
        this.setAgentAssnData(data)
        break
      case TYPE.MORTGAGE_DEAL:
        this.setMortgageData(data)
        break
      case TYPE.MORTGAGE_DEAL_CLIENT_ASSN:
        this.setClientAssnData(data)
        break
      case TYPE.MORTGAGE_DEAL_COMMENT:
        this.setCommentData(data)
        break
      case TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL:
        this.setProgramControlData(data)
        break
    }
  }

  /**
   * Sets the specified type to its default value.
   * @param {string} type MortgageDeal.TYPE
   */
  setToDefaultData(type) {
    super.setData(DEFAULTS[type], type)
  }
}