import FieldConfig, { FIELD_TYPES } from './FieldConfig'

/**
 * TextFieldConfig for Vuetify text fields.
 */
export default class TextFieldConfig extends FieldConfig {

  /**
   * Create a TextFieldConfig object.
   * @param {string} property Data-type property represented by the field.
   * @param {string} label Label of the field.
   * @param {string} smColumns Number of columns (1-12) that this field spans at the "sm" breakpoint or larger.
   * @param {string} mdColumns Number of columns (1-12) that this field spans at the "md" breakpoint or larger.
   * @param {string} iconPrepend Icon to prepend the field with. Default is null.
   * @param {boolean} required Indicate that the field requires a value.
   * @param {number} counter Number of characters this field can support.
   * @param {array} rules Array of validation rules for this field.
   */
   constructor ({ property, label, smColumns, mdColumns, iconPrepend, required, counter, rules }) {
    super({ fieldType: FIELD_TYPES.TEXT, property, label, smColumns, mdColumns, iconPrepend, required, })

    this.counter = counter
    this.rules = rules
  }

  getCounter () {
    return this.counter
  }

  getRules () {
    return this.rules
  }
}