import FieldConfig, { FIELD_TYPES } from './FieldConfig'

/**
 * SelectFieldConfig for Vuetify text fields.
 */
export default class SelectFieldConfig extends FieldConfig {

  /**
   * Create a SelectFieldConfig object.
   * @param {string} property Data-type property represented by the field.
   * @param {string} label Label of the field.
   * @param {string} smColumns Number of columns (1-12) that this field spans at the "sm" breakpoint or larger.
   * @param {string} mdColumns Number of columns (1-12) that this field spans at the "md" breakpoint or larger.
   * @param {string} iconPrepend Icon to prepend the field with.
   * @param {string} textProperty Display the text of this property. Use when the select is populated with objects.
   * @param {string} items Items to populate the select element with.
   */
   constructor ({ property, label, smColumns, mdColumns, iconPrepend, textProperty, items, required }) {
    super({ fieldType: FIELD_TYPES.SELECT, property, label, smColumns, mdColumns, iconPrepend, required, })

    this.textProperty = textProperty
    this.items = items
  }

  getTextProperty() {
    return this.textProperty
  }

  getItems() {
    return this.items
  }
}