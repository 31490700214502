import Repository from './repository'

export const URL = {
  BASE: 'calendar-event',
  UPCOMING_EVENTS: 'upcoming-events',
}

class CalendarEventService {
  create(data) {
    return Repository.post(`${URL.BASE}/`, data)
  }

  delete(event_id) {
    return Repository.delete(`${URL.BASE}/${event_id}/`)
  }

  detail(event_id) {
    return Repository.get(`${URL.BASE}/${event_id}/`)
  }

  list() {
    return Repository.get(`${URL.BASE}/`)
  }

  update(data) {
    return Repository.put(`${URL.BASE}/${data.id}/`, data)
  }

  listUpcomingEvents() {
    return Repository.get(`${URL.BASE}/${URL.UPCOMING_EVENTS}/`)
  }
}

const calendarEventService = new CalendarEventService()
export default calendarEventService