<template>
  <v-navigation-drawer
    v-model="inputValue"
    app
    fixed
    height="100vh"
  >
    <v-list nav flat>
      <v-list-item @click="pushTo(DASHBOARD_ROUTE)">
        <v-list-item-icon>
          <v-icon class="primary--text">{{ MONITOR_ICON }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="primary--text">
          <strong>iCare</strong> Dashboard
        </v-list-item-title>
      </v-list-item>

      <v-skeleton-loader v-if="!userName" type="list-item" />
      <v-list-item v-else @click="pushTo(ACCOUNT_ROUTE)">
        <v-list-item-icon>
          <v-icon>{{ ACCOUNT_SETTINGS_ICON }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ userName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
  
      <v-list-item-group
        v-model="selection"
        active-class="primary--text"
        color="primary"
      >
        <template v-for="(group, index) in navGroups">
          <v-subheader :key="group.title + '0'">{{ group.title }}</v-subheader>
          
          <v-list-item
            v-for="link in group.links" :key="navLink(link).text"
            @click="pushTo(navLink(link).route)"
          >
            <v-list-item-icon>
              <v-icon v-text="navLink(link).icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="navLink(link).text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="index !== navGroups.length" :key="group.title + '1'"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { ICONS, ROUTES, } from '../../constants'
import { push } from '../../router'

export default {
  name: "AppNavigationDrawer",
  props: {
    value: {
      type: Boolean,
    },
  },
  data: () => ({
    selection: null,
  }),
  computed: {
    ...mapGetters({
      navGroups: 'sitemap/getNavigationGroups',
      navLink: 'sitemap/getNavigationLink',
      userName: 'user/getUserName'
    }),
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    initTemplateConstants() {
      this.ACCOUNT_SETTINGS_ICON = ICONS.ACCOUNT_SETTINGS
      this.MONITOR_ICON = ICONS.MONITOR

      this.ACCOUNT_ROUTE = ROUTES.ACCOUNT
      this.DASHBOARD_ROUTE = ROUTES.DASHBOARD
    },
    pushTo(link) {
      push(link)
    }
  },
  created () {
    this.initTemplateConstants()
  },
}
</script>