import { filter, isArray, isEmpty, isNil, reject } from "lodash"

import { YES_OR_NO } from "../../constants"
import DataTransferObject from "./DataTransferObject"
import FieldConfigFactory from "../field_configs/FieldConfigFactory"
import ValidationRulesFactory from "../validation_rules/ValidationRulesFactory"

// Client types.
export const TYPE = {
  CLIENT: 'client',
  CLIENT_ADDRESS: 'client_address',
  CLIENT_CONTACT: 'client_contact',
  CLIENT_PROGRAM_CONTROL: 'client_program_control',
  CLIENT_AGENT_ASSN: 'client_agent_assn',
  CLIENT_MORTGAGE_DEAL_ASSN: 'client_mortgage_deal_assn',
  CO_APP: 'co_app',
  CO_APP_CONTACT: 'co_app_contact',
  CO_APP_PROGRAM_CONTROL: 'co_app_program_control',
}

// Client-type properties.
export const PROPERTY = {
  ADDRESS: 'address',
  BOUNCE: 'bounce',
  CITY: 'city',
  CLIENT_ID: 'client_id',
  DATE_OF_BIRTH: 'date_of_birth',
  EMAIL_ADDRESS: 'email_address',
  FIRST_NAME: 'first_name',
  ID: 'id',
  IS_CO_APPLICANT: 'is_co_applicant',
  POSTAL_CODE: 'postal_code',
  PROVINCE: 'province',
  SUPPRESS_EMAIL: 'suppress_email',
  SURNAME: 'surname',
  UNSUBSCRIBE: 'unsubscribe',
  VALID_ADDRESS: 'valid_address',
}

// Co-applicant properties.
export const CO_APP_PROPERTY = {
  BOUNCE: 'coapp_bounce',
  CLIENT_ID: 'coapp_id',
  DATE_OF_BIRTH: 'coapp_date_of_birth',
  EMAIL_ADDRESS: 'coapp_email_address',
  FIRST_NAME: 'coapp_first_name',
  IS_CO_APPLICANT: 'coapp_is_co_applicant',
  SUPPRESS_EMAIL: 'coapp_suppress_email',
  SURNAME: 'coapp_surname',
  UNSUBSCRIBE: 'coapp_unsubscribe',
}

// Mapping of Client PROPERTY values to validation properties.
// These are used as keys in the VALIDATION_RULES.
export const VALIDATE_PROPERTY = {
  [PROPERTY.ADDRESS]: 'client_address',
  [PROPERTY.BOUNCE]: 'client_bounce',
  [PROPERTY.CITY]: 'client_city',
  [PROPERTY.CLIENT_ID]: 'client_id',
  [PROPERTY.DATE_OF_BIRTH]: 'client_date_of_birth',
  [PROPERTY.EMAIL_ADDRESS]: 'client_email_address',
  [PROPERTY.FIRST_NAME]: 'client_first_name',
  [PROPERTY.ID]: 'id',
  [PROPERTY.IS_CO_APPLICANT]: 'client_is_co_applicant',
  [PROPERTY.POSTAL_CODE]: 'client_postal_code',
  [PROPERTY.PROVINCE]: 'client_province',
  [PROPERTY.SUPPRESS_EMAIL]: 'client_suppress_email',
  [PROPERTY.SURNAME]: 'client_surname',
  [PROPERTY.UNSUBSCRIBE]: 'client_unsubscribe',
  [PROPERTY.VALID_ADDRESS]: 'client_valid_address',
}

// Default values for primary and secondary Client data-types.
const DEFAULTS = {
  [TYPE.CLIENT]: { default: true, client_id: '', operator_id: '', dlc: '', first_name: '', surname: '', date_of_birth: '2000-01-01', is_co_applicant: 'N', },
  [TYPE.CLIENT_ADDRESS]: { default: true, id: '', client_id: '', operator_id: '', dlc: '', address: '', city: '', postal_code: '', valid_address: 'Y', province: 'AB', country: 'CA', },
  [TYPE.CLIENT_CONTACT]: { default: true, id: '', client_id: '', operator_id: '', dlc: '', email_address: '', },
  [TYPE.CLIENT_PROGRAM_CONTROL]: { default: true, id: '', client_id: '', operator_id: '', dlc: '', suppress_email: 'N', bounce: '', unsubscribe: '', },
  [TYPE.CLIENT_AGENT_ASSN]: [],
  [TYPE.CLIENT_MORTGAGE_DEAL_ASSN]: [],
}

// Validation rules applied to form fields and CSV files.
export const VALIDATION_RULES = {
  // Both the client and co-applicant are saved in the 'client' table in the database.
  // Due to the design, we must duplicate the client validation config rather than re-use
  // the client. Any changes a client property must reflect in both the client and co-applicant
  // validation.
  [TYPE.CLIENT]: {
    [PROPERTY.CLIENT_ID]: ValidationRulesFactory.makeIntRules(),
    [VALIDATE_PROPERTY[PROPERTY.DATE_OF_BIRTH]]:   ValidationRulesFactory.makeDateRules({ nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.FIRST_NAME]]:      ValidationRulesFactory.makeStringRules({ length: 50, }),
    [VALIDATE_PROPERTY[PROPERTY.SURNAME]]:         ValidationRulesFactory.makeStringRules({ length: 50, }),
    [VALIDATE_PROPERTY[PROPERTY.IS_CO_APPLICANT]]: ValidationRulesFactory.makeYesOrNoRules(),
  },
  [TYPE.CLIENT_ADDRESS]: {
    [VALIDATE_PROPERTY[PROPERTY.ADDRESS]]:       ValidationRulesFactory.makeStringRules({ length: 100, }),
    [VALIDATE_PROPERTY[PROPERTY.CITY]]:          ValidationRulesFactory.makeStringRules({ length: 80, }),
    [VALIDATE_PROPERTY[PROPERTY.POSTAL_CODE]]:   ValidationRulesFactory.makePostalCodeRules(),
    [VALIDATE_PROPERTY[PROPERTY.PROVINCE]]:      ValidationRulesFactory.makeProvinceRules(),
    [VALIDATE_PROPERTY[PROPERTY.VALID_ADDRESS]]: ValidationRulesFactory.makeYesOrNoRules(),
  },
  [TYPE.CLIENT_CONTACT]: {
    [VALIDATE_PROPERTY[PROPERTY.EMAIL_ADDRESS]]: ValidationRulesFactory.makeStringRules({ length: 255, nullable: true, }),
  },
  [TYPE.CLIENT_PROGRAM_CONTROL]: {
    [VALIDATE_PROPERTY[PROPERTY.SUPPRESS_EMAIL]]: ValidationRulesFactory.makeYesOrNoRules(),
    [VALIDATE_PROPERTY[PROPERTY.BOUNCE]]:         ValidationRulesFactory.makeStringRules({ length: 10, nullable: true, }),
    [VALIDATE_PROPERTY[PROPERTY.UNSUBSCRIBE]]:    ValidationRulesFactory.makeStringRules({ length: 10, nullable: true, }),
  },
  // Co-applicant validation.
  [TYPE.CO_APP]: {
    [CO_APP_PROPERTY.CLIENT_ID]:       ValidationRulesFactory.makeIntRules(),
    [CO_APP_PROPERTY.DATE_OF_BIRTH]:   ValidationRulesFactory.makeDateRules({ nullable: true, }),
    [CO_APP_PROPERTY.FIRST_NAME]:      ValidationRulesFactory.makeStringRules({ length: 50, }),
    [CO_APP_PROPERTY.SURNAME]:         ValidationRulesFactory.makeStringRules({ length: 50, }),
    [CO_APP_PROPERTY.IS_CO_APPLICANT]: ValidationRulesFactory.makeYesOrNoRules(),
  },
  [TYPE.CO_APP_CONTACT]: {
    [CO_APP_PROPERTY.EMAIL_ADDRESS]: ValidationRulesFactory.makeStringRules({ length: 255, nullable: true, }),
  },
  [TYPE.CO_APP_PROGRAM_CONTROL]: {
    [CO_APP_PROPERTY.SUPPRESS_EMAIL]: ValidationRulesFactory.makeYesOrNoRules({ nullable: true, }),
    [CO_APP_PROPERTY.BOUNCE]:         ValidationRulesFactory.makeStringRules({ length: 10, nullable: true, }),
    [CO_APP_PROPERTY.UNSUBSCRIBE]:    ValidationRulesFactory.makeStringRules({ length: 10, nullable: true, }),
  },
}

/**
 * Get the length rule value from a validation rule.
 * @param {string} type Client.TYPE
 * @param {*} property Client.VALIDATE_PROPERTY
 * @returns Length rule value.
 */
const getLength = (type, property) => {
  if ('length' in VALIDATION_RULES[type][property]) {
    return VALIDATION_RULES[type][property].length
  }
  throw new ReferenceError(`Rule for ${type}.${property} does not have a 'length' property.`)
}

/**
 * Get rules array from a validation rule.
 * @param {string} type Client.TYPE
 * @param {*} property Client.VALIDATE_PROPERTY
 * @returns Length rule value.
 */
const getRules = (type, property) => {
  if ('rules' in VALIDATION_RULES[type][property]) {
    return VALIDATION_RULES[type][property].rules
  }
  throw new ReferenceError(`Rule for ${type}.${property} does not have a 'rules' property.`)
}

/**
 * Get specific rules for a text field config.
 * @param {string} type Client.TYPE
 * @param {*} property Client.VALIDATE_PROPERTY
 * @returns Object containing the counter and rules for a text field.
 */
const getTextRules = (type, property) => {
  const validationProperty = VALIDATE_PROPERTY[property]
  return {
    property,
    counter: getLength(type, validationProperty),
    rules: getRules(type, validationProperty),
  }
}

/**
 * Get specific rules for a date field config.
 * @param {string} type Client.TYPE
 * @param {*} property Client.VALIDATE_PROPERTY
 * @returns Object containing the counter and rules for a text field.
 */
const getDateRules = (type, property) => {
  const validationProperty = VALIDATE_PROPERTY[property]
  return {
    property,
    rules: getRules(type, validationProperty),
  }
}

/**
* Determine if members of the specified type are iterable.
* @param {string} type Client.TYPE 
* @returns True if the data-type is iterable, false if the datat-type is a single object.
*/
export const isTypeIterable = (type) => Array.isArray(DEFAULTS[type])

// Client field metadata.
const FIELD_METADATA = {
  [TYPE.CLIENT]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.CLIENT, PROPERTY.FIRST_NAME), smColumns: 6, mdColumns: 6, label: 'First Name', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.CLIENT, PROPERTY.SURNAME), smColumns: 6, mdColumns: 6, label: 'Surname', }),
        FieldConfigFactory.makeDateFieldConfig({ ...getDateRules(TYPE.CLIENT, PROPERTY.DATE_OF_BIRTH), smColumns: 3, mdColumns: 3, label: 'Date of Birth', }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 3, mdColumns: 3, label: 'Is Co-Applicant?', property: PROPERTY.IS_CO_APPLICANT, items: YES_OR_NO, }),
      ],
    ],
  },
  [TYPE.CLIENT_ADDRESS]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.CLIENT_ADDRESS, PROPERTY.ADDRESS), label: 'Address', }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.CLIENT_ADDRESS, PROPERTY.CITY), smColumns: 6, mdColumns: 4, label: 'City', }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 3, mdColumns: 2, label: 'Province', property: PROPERTY.PROVINCE, textProperty: PROPERTY.PROVINCE, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.CLIENT_ADDRESS, PROPERTY.POSTAL_CODE), smColumns: 3, mdColumns: 2, label: 'Postal Code', }),
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 3, mdColumns: 2, label: 'Is address valid?', property: PROPERTY.VALID_ADDRESS, items: YES_OR_NO, }),
      ],
    ],
  },
  [TYPE.CLIENT_CONTACT]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.CLIENT_CONTACT, PROPERTY.EMAIL_ADDRESS), smColumns: 9, mdColumns: 10, label: 'Email Address', property: PROPERTY.EMAIL_ADDRESS, required: false, }),
      ],
    ],
  },
  [TYPE.CLIENT_PROGRAM_CONTROL]: {
    fieldGroups: [
      [
        FieldConfigFactory.makeSelectFieldConfig({ smColumns: 4, mdColumns: 3, label: 'Suppress Email?', property: PROPERTY.SUPPRESS_EMAIL, items: YES_OR_NO, required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.CLIENT_PROGRAM_CONTROL, PROPERTY.BOUNCE), smColumns: 12, mdColumns: 12, label: 'Bounce', property: PROPERTY.BOUNCE, required: false, }),
        FieldConfigFactory.makeTextFieldConfig({ ...getTextRules(TYPE.CLIENT_PROGRAM_CONTROL, PROPERTY.UNSUBSCRIBE), smColumns: 12, mdColumns: 12, label: 'Unsubscribe', property: PROPERTY.UNSUBSCRIBE, required: false, }),
      ],
    ],
  },
}

/**
 * Set valid data for select fields.
 * @param {*} object An object containing arrays of valid data.
 */
export const setSelectValidData = ({ provinces = null }) => {
  if (provinces) {
    FIELD_METADATA[TYPE.CLIENT_ADDRESS].fieldGroups[0][2].items = provinces
  }
}

export default class Client extends DataTransferObject {
  /**
   * Create a Client from a DTO.
   * @param {*} dto DTO containing the primary and secondary data types of a Client.
   */
  constructor (dto) {
    super()

    if (!isNil(dto)) {
      this.setClientData(dto[TYPE.CLIENT])
      this.setAddressData(dto[TYPE.CLIENT_ADDRESS])
      this.setAgentAssnData(dto[TYPE.CLIENT_AGENT_ASSN])
      this.setContactData(dto[TYPE.CLIENT_CONTACT])
      this.setProgramControlData(dto[TYPE.CLIENT_PROGRAM_CONTROL])
      this.setMortgageDealAssnData(dto[TYPE.CLIENT_MORTGAGE_DEAL_ASSN])

      super.setEdit(false, TYPE.CLIENT)
      super.setEdit(false, TYPE.CLIENT_ADDRESS)
      super.setEdit(false, TYPE.CLIENT_CONTACT)
      super.setEdit(false, TYPE.CLIENT_PROGRAM_CONTROL)
    }

    this.metadata = FIELD_METADATA
  }

  /**
   * Append data to the specified type.
   * @param {*} data 
   * @param {string} type Client.TYPE
   */
   appendData (data, type) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`Client data-type '${type}' is not iterable.`)
    }
    super.appendData(data, type)
  }

  /**
   * Disable editing of this Client and restore the type's data object to a pre-edit state.
   * @param {string} type Client.TYPE
   */
  cancelEdit (type) {
    this.disableEdit(type)
    super.restoreData(type)
  }

  /**
   * Delete a member item from the specified iterable type.
   * @param {*} item Item to delete.
   * @param {*} type 
   */
  deleteData (item, type) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`Client data-type '${type}' is not iterable.`)
    }

    let newData = null

    switch (type) {
      case TYPE.CLIENT_AGENT_ASSN:
        if (!item.agent_id || !item.profile_id || !item.client_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, profile_id, client_id].`)
        }
        newData = reject(super.getData(type), { agent_id: item.agent_id, profile_id: item.profile_id, client_id: item.client_id, })
        this.setAgentAssnData(newData)
        break

      case TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
        if (!item.mortgage_deal_id || !item.client_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [mortgage_deal_id, client_id].`)
        }
        newData = reject(super.getData(type), { mortgage_deal_id: item.mortgage_deal_id, client_id: item.client_id, })
        this.setMortgageDealAssnData(newData)
        break

      default:
        throw new TypeError(`Client data-type '${type}' does not have member item deletion implemented.`)
    }
  }
  
  /**
   * Disable editing of the specified type.
   * @param {string} type Client.TYPE
   */
  disableEdit (type) {
    super.setEdit(false, type)
  }

  /**
   * Enable editing of the specified type and save the state of the type's data object.
   * @param {string} type Client.TYPE
   */
  enableEdit (type) {
    super.saveData(type)
    super.setEdit(true, type)
  }

  /**
   * Determine if a specific type is being edited.
   * @param {string} type Client.TYPE
   * @returns True if that type is editing, false otherwise.
   */
  isEditing (type) {
    return super.getEdit(type)
  }

  getAddressProperty (property) {
    return super.getProperty(TYPE.CLIENT_ADDRESS, property)
  }

  getAgentAssociationCount () {
    return super.getData(TYPE.CLIENT_AGENT_ASSN).length
  }

  getClientProperty (property) {
    return super.getProperty(TYPE.CLIENT, property)
  }

  getContactProperty (property) {
    return super.getProperty(TYPE.CLIENT_CONTACT, property)
  }

  getProgramControlProperty (property) {
    return super.getProperty(TYPE.CLIENT_PROGRAM_CONTROL, property)
  }

  getDefault(type) {
    return DEFAULTS[type]
  }

  /**
   * Get the field groups of the specified type.
   * @param {string} type Client.TYPE
   * @returns Array of field groups object.
   */
  getFieldGroups (type) {
    return this.metadata[type].fieldGroups
  }

  /**
   * Get the member from the specified iterable type that equals the item.
   * @param {string} type An iterable Client.TYPE
   * @param {*} item An object of specified-type.
   * @returns Data object of iterable type.
   */
  getIterableData (type, item) {
    if (!isTypeIterable(type)) {
      throw new TypeError(`Client data-type '${type}' is not iterable. Use Client.getData() instead.`)
    }

    switch (type) {
      case TYPE.CLIENT_AGENT_ASSN:
        if (!item.agent_id || !item.profile_id || !item.client_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [agent_id, profile_id, client_id].`)
        }
        return filter(super.getData(type), { agent_id: item.agent_id, profile_id: item.profile_id, client_id: item.client_id, })

      case TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
        if (!item.mortgage_deal_id || !item.client_id) {
          throw new TypeError(`Item of '${type}' is missing an one or more of: [mortgage_deal_id, client_id].`)
        }
        return filter(super.getData(type), { mortgage_deal_id: item.mortgage_deal_id, client_id: item.client_id, })

      default:
        throw new TypeError(`Client data-type '${type}' does not have getIterableData() implemented.`)
    }
  }

  getMortgageDealAssociationCount () {
    return super.getData(TYPE.CLIENT_MORTGAGE_DEAL_ASSN).length
  }

  /**
   * Name of the Client.
   * @returns First name and surname of the Client.
   */
  getName () {
    return `${this.getClientProperty(PROPERTY.FIRST_NAME)} ${this.getClientProperty(PROPERTY.SURNAME)}`
  }

  /**
   * Get a string containing a summary of a Client data-type.
   * @param {string} type Client.TYPE
   * @returns A summary of that data-type.
   */
  getSummaryText (type) {
    switch(type) {
      case TYPE.CLIENT:
        return `${this.getName()}${this.isCoApplicant() ? ' ∙ co-applicant' : ''}`

      case TYPE.CLIENT_ADDRESS:
        const address = `${this.getAddressProperty(PROPERTY.ADDRESS)}, ${this.getAddressProperty(PROPERTY.CITY)} ∙ ${this.getAddressProperty(PROPERTY.PROVINCE)} ∙ ${this.getAddressProperty(PROPERTY.POSTAL_CODE)}`
        return this.hasAddress() ? address : 'No address information.'

      case TYPE.CLIENT_CONTACT:
        return this.hasContact() ? this.getContactProperty(PROPERTY.EMAIL_ADDRESS) : 'No contact information.'

      case TYPE.CLIENT_AGENT_ASSN:
        return this.getAgentAssociationCount().toString()

      case TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
        return this.getMortgageDealAssociationCount().toString()
      
      case TYPE.CLIENT_PROGRAM_CONTROL:
        const isEmailSuppressed = this.getProgramControlProperty(PROPERTY.SUPPRESS_EMAIL) === 'Y'
        return isEmailSuppressed ? 'Email Suppressed' : 'Email Permitted'
    }
  }

  /**
   * Checks if the Client has an address data object.
   * @returns True if the address is non-empty, false otherwise.
   */
  hasAddress () {
    return !isEmpty(super.getData(TYPE.CLIENT_ADDRESS))
  }

  /**
   * Checks if the Client has a contact data object.
   * @returns True if the contact is non-empty, false otherwise.
   */
  hasContact () {
    return !isEmpty(super.getData(TYPE.CLIENT_CONTACT))
  }

  /**
   * Checks if the Client is a co-applicant.
   * @returns True if the Client is a co-applicant, false otherwise.
   */
  isCoApplicant () {
    return this.getClientProperty(PROPERTY.IS_CO_APPLICANT) === 'Y'
  }

  /**
   * Sets the address data for this Client. Converts the data into a single object if wrapped in an array.
   * @param {*} data 
   */
  setAddressData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.CLIENT_ADDRESS]
    }
    super.setData(data, TYPE.CLIENT_ADDRESS)
  }

  /**
   * Sets the agent association data for this Client.
   * @param {*} data 
   */
  setAgentAssnData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.CLIENT_AGENT_ASSN]
    }
    super.setData(data, TYPE.CLIENT_AGENT_ASSN)
  }

  /**
   * Sets the client data for this Client.
   * @param {*} data 
   */
  setClientData (data) {
    super.validateData(data)
    super.setData(data, TYPE.CLIENT)
  }

  /**
   * Sets the contact data for this Client. Converts the data into a single object if wrapped in an array.
   * @param {*} data 
   */
  setContactData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.CLIENT_CONTACT]
    }
    super.setData(data, TYPE.CLIENT_CONTACT)
  }

  /**
   * Set the data object for a specified type.
   * @override DataTransferObject.setData()
   * @param {*} data 
   * @param {string} type Client.TYPE
   */
  setData(data, type) {
    switch (type) {
      case TYPE.CLIENT:
        this.setClientData(data)
        break
      case TYPE.CLIENT_ADDRESS:
        this.setAddressData(data)
        break
      case TYPE.CLIENT_AGENT_ASSN:
        this.setAgentAssnData(data)
        break
      case TYPE.CLIENT_CONTACT:
        this.setContactData(data)
        break
      case TYPE.CLIENT_MORTGAGE_DEAL_ASSN:
        this.setMortgageDealAssnData(data)
        break
      case TYPE.CLIENT_PROGRAM_CONTROL:
        this.setProgramControlData(data)
        break
    }
  }

  /**
   * Sets the mortgage deal association data for this Client.
   * @param {*} data 
   */
  setMortgageDealAssnData (data) {
    super.validateData(data)
    if (!isArray(data)) {
      data = DEFAULTS[TYPE.CLIENT_MORTGAGE_DEAL_ASSN]
    }
    super.setData(data, TYPE.CLIENT_MORTGAGE_DEAL_ASSN)
  }

  /**
   * Sets the program control data for this Client.
   * @param {*} data 
   */
  setProgramControlData (data) {
    super.validateData(data)
    if (isArray(data)) {
      data = (data.length > 0) ? data[0] : DEFAULTS[TYPE.CLIENT_PROGRAM_CONTROL]
    }
    super.setData(data, TYPE.CLIENT_PROGRAM_CONTROL)
  }

  /**
   * Sets the specified type to its default value.
   * @param {string} type Client.TYPE
   */
  setToDefaultData(type) {
    super.setData(DEFAULTS[type], type)
  }
}