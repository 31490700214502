import { getField, updateField } from 'vuex-map-fields'
import { ICONS, ROUTES, } from '../../constants'

const sitemap = {
  namespaced: true,
  state: {
    background: null,

    // Groups containing arrays of navigationLinks
    navigationGroups: {
      0: {
        title: 'iCare',
        links: [0, 1, 2, 3, 4,],
      },
      1: {
        title: 'Data',
        links: [5, 6,],
      },
    },
    navigationLinks: {
      0: { icon: ICONS.REPORTS, text: 'Reports', route: ROUTES.REPORTS, description: 'View reports on iCare data.', },
      1: { icon: ICONS.MORTGAGE_DEAL, text: 'Mortgage Deals', route: ROUTES.MORTGAGE_DEALS, description: 'View and edit iCare mortgage deals.', },
      2: { icon: ICONS.AGENT, text: 'Agents', route: ROUTES.AGENTS, description: 'View and edit iCare agents.', },
      3: { icon: ICONS.CLIENTS, text: 'Clients', route: ROUTES.CLIENTS, description: 'View and edit iCare clients.', },
      4: { icon: ICONS.CALENDAR, text: 'Mailing Schedule', route: ROUTES.MAILING_SCHEDULE, description: 'View and edit the mailing schedule.', },
      5: { icon: ICONS.UPLOAD_TO_DATABASE, text: 'Load Data', route: ROUTES.LOAD_DATA, description: 'Load data into the iCare system.', },
      6: { icon: ICONS.TABLE, text: 'Valid Tables', route: ROUTES.VALID_TABLES, description: 'View and edit iCare meta-data.', }, 
    },
  },
  getters: {
    getField,

    getBackgroundStyle (state) {
      return {
        'background-image': 'linear-gradient(to bottom, rgba(255,255,255,.0), rgba(255,255,255,.33)), url(' + state.background + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center',
        'background-attachment': 'fixed',
      }
    },
    getNavigationGroups (state) {
      return state.navigationGroups
    },
    getNavigationLink: (state) => (index) => {
      return state.navigationLinks[index]
    },
  },
  mutations: {
    updateField,

    setBackground(state) {
      if (!state.background) {
        state.background = require(`../../assets/backgrounds/house${ Math.floor(Math.random() * 10) }b.jpg`)
      }
    },
  },
  actions: {
    setBackground({ commit, getters }) {
      commit('setBackground')
    },
  },
}

export default sitemap